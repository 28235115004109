
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const SatisSale = ({ salesData }) => {
    const labels = salesData.map(item => item.name);
    const totalOrdersData = salesData.map(item => item.totalOrders);
    const averageDailySalesData = salesData.map(item => item.averageDailySales);
    const totalRevenueData = salesData.map(item => item.totalRevenue);

    const dataAllTime = {
        labels: labels,
        datasets: [
            {
                label: 'Total Orders',
                data: totalOrdersData,
                fill: true,
                backgroundColor: 'rgba(116, 126, 239, 0.2)',
                borderColor: '#747EEF',
                borderWidth: 1,
            },
            {
                label: 'Average Daily Sales',
                data: averageDailySalesData,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Total Revenue',
                data: totalRevenueData,
                fill: true,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    font: {
                        family: 'Manrope',
                        color: 'black',
                    },
                },
            },
        },
    };

    return (
        <div className="chart-container" style={{ background: '#f7f7f7', boxShadow: 'none', border: '0.5px solid #747EEF' }}>
            <h2 className="main-heading2">Total Sales</h2>
            <div className="div-chart-div">
                <Line data={dataAllTime} options={options} />
            </div>
        </div>
    );
};

export default SatisSale;
