// src/redux/statsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    acceptedOrders: 0,
    averageMonthlySales: 0,
    totalCancelledOrders: 0,
    totalNumberOfOrders: 0,
    totalRevenue: 0,
    averageDailySales:0,
    totalCompletedOrders:0,
    totalAcceptedOrders:0
  },
  reducers: {
    setStats: (state, action) => {
      state.acceptedOrders = action.payload.acceptedOrders;
      state.averageMonthlySales = action.payload.averageMonthlySales;
      state.totalCancelledOrders = action.payload.totalCancelledOrders;
      state.totalNumberOfOrders = action.payload.totalNumberOfOrders;
      state.totalRevenue = action.payload.totalRevenue;
      state.averageDailySales = action.payload.averageDailySales;
      state.totalCompletedOrders = action.payload.totalCompletedOrders;
      state.totalAcceptedOrders = action.payload.totalAcceptedOrders;

    },
  },
});

export const { setStats } = statsSlice.actions;

export default statsSlice.reducer;