import AgentSidebar from 'agentfolder/sidebar/agentSideBar';
import { Skeleton } from '@mui/material';
import NoDataImage from '../../assets/images/nodataImg.png'; // Add the path to your image here
import LOGO from '../../assets/images/app.logo.jpeg';
import DonutChart from 'admin/components/jobhistory/companies/DonutChart';
import ReviewSummary from 'pages/clientDashbaord/dashboard/ratingandReviews/ReviewSummary';
import React, { useEffect, useState } from 'react';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../Socket';

const AgentChatSupport = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const socket = useSocket();
  const navigate = useNavigate();

  const handleChatRoomClick = (item) => {
    
    socket.emit('join', {ticketId: item._id });
    navigate('/agent/agent-chats', { state: { chatdata: item } });
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('agentToken');
      const apiUrl = 'https://backend.washta.com/api/agent/support?New=false';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup socket connection on unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  const averageRating = 4.5;
  const totalReviews = 273;
  const ratings = {
    1: 150,
    2: 70,
    3: 30,
    4: 15,
    5: 8,
  };
  const recommendationPercentage = 88;

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AgentSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='row-div-admin-dashboard'>
          <h1 className='h1-all-heading'>Chat Support</h1>
        </div>
        <div className="dashboard" style={{ width: '100%' }}>
          <div className='destils-row-div' style={{ width: '100%', alignItems: "inherit", justifyContent: 'start' }}>
            <div className='colm-statis-detsils-company' style={{ width: '40%' }}>
              <DonutChart />
            </div>
            <div className='colm-statis-detsils-company'>
              <ReviewSummary
                boxShadow={'none'}
                border={'0.5px solid #747eef '}
                margin={0}
                color={"#f7f7f7"}
                text="Customer Ratings"
                ratings={ratings}
                averageRating={averageRating}
                totalReviews={totalReviews}
                recommendationPercentage={recommendationPercentage}
              />
            </div>
          </div>
        </div>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '92%' }}>
            <div className="chart-container">
              <div className='flex' style={{ justifyContent: 'space-between' }}>
                <div className='flex' style={{ width: '50%', alignItems: 'center' }}>
                  <h2 className='main-heading2'>Active Chats</h2>
                </div>
              </div>
              <div className='styles-div' style={{ width: '100%' }}>
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div key={index} style={{ background: '#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px', marginTop: 20 }}>
                      <Skeleton variant="rectangular" width={48} height={53} style={{ marginRight: '10px', borderRadius: '8px', background: '#747eef9c' }} />
                      <div style={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                        <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                        <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                        <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                        <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                      </div>
                    </div>
                  ))
                ) : data.length === 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={NoDataImage} alt="No Data" style={{ width: '100%', height: '100%' }} />
                  </div>
                ) : (
                  data.map((item) => (
                    <div className='main-invoice-div' style={{ marginTop: 15, cursor: 'pointer' }} key={item._id} onClick={() => handleChatRoomClick(item)}>
                      <div className='row-invoice' style={{ width: '100%' }}>
                        <div className='row-invoice' style={{ justifyContent: 'flex-start', width: '100%' }}>
                          <div className='div-text-image-immer-text' style={{ width: '10%', paddingLeft: 0 }}>
                            <img src={item?.avatar || LOGO} style={{ width: '100%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%', height: '3em', objectFit: 'cover' }} />
                          </div>
                          <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                            <h5 className='h5-heaidng-invouice'>Username</h5>
                            <p className='paragph-heading'>{item?.user?.username}</p>
                          </div>
                          <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                            <h5 className='h5-heaidng-invouice'>Title</h5>
                            <p className='paragph-heading'>{item.title}</p>
                          </div>
                          <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                            <h5 className='h5-heaidng-invouice'>Date</h5>
                            <p className='paragph-heading'>{`${formatDate(item?.createdAt)} || ${formatTimeInTimezone(item?.createdAt)}`}</p>
                          </div>
                          <div className='div-text-image-immer-text' style={{ width: '30%' }}>
                            <h5 className='h5-heaidng-invouice'>Request Status</h5>
                            <p className='paragph-heading'>{item.requestStatus}</p>
                          </div>
                          <div className='div-text-image-immer-text' style={{ width: '20%' }}>
                            <h5 className='h5-heaidng-invouice'>Is Joined</h5>
                            <p className='paragph-heading'>{item.isSomeOneConnected ? 'Yes' : 'No'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentChatSupport;
