// src/utils/formatDate.js
import moment from 'moment-timezone';

export const formatDateconvert = (dateString) => {
  return moment.tz(dateString, 'UTC').format('DD MMM YYYY');
};
export const formatDate = (timestamp) => {
  return moment(timestamp).format('MMM DD');
};
export const formatTimeInTimezone = (timestamp) => {
  // Automatically get the user's local timezone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC timestamp to the user's local timezone
  return moment.utc(timestamp).tz(userTimezone).format('hh:mm A');
};

