import React from 'react';
import { FaEdit } from 'react-icons/fa'; // Importing the Edit icon from react-icons

const GetAllShop = ({ data }) => {
  // Function to format the time in hours and minutes
  const formatTime = (time) => {
    const date = new Date(time);
    return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  };

  // Function to render shop timing for each day
  const renderTiming = (timing) => {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const openDays = [];
    const closedDays = [];

    // Separate open and closed days
    days.forEach((day) => {
      const dayTiming = timing[day];
      const isOpen = dayTiming?.open;
      if (isOpen) {
        openDays.push(`${day.charAt(0).toUpperCase() + day.slice(1)}: Open from ${formatTime(dayTiming.from)} to ${formatTime(dayTiming.to)}`);
      } else {
        closedDays.push(day.charAt(0).toUpperCase() + day.slice(1));
      }
    });

    // Group consecutive closed days into one entry
    const closedGroups = [];
    let tempGroup = [];
    for (let i = 0; i < closedDays.length; i++) {
      if (i === 0 || closedDays[i] === days[days.indexOf(closedDays[i-1]) + 1]) {
        tempGroup.push(closedDays[i]);
      } else {
        closedGroups.push(tempGroup.join(", "));
        tempGroup = [closedDays[i]];
      }
    }
    if (tempGroup.length > 0) {
      closedGroups.push(tempGroup.join(", "));
    }

    return (
      <div>
        {/* Render open days */}
        {openDays.map((day, index) => (
          <div key={index}>{day}</div>
        ))}

        {/* Render closed days as grouped */}
        {closedGroups.length > 0 && (
          <div>Off: {closedGroups.join(' ')}</div>
        )}
      </div>
    );
  };

  // Handle the delete action
  const handleDelete = (promoId) => {
    console.log('Deleted promo with ID:', promoId);
    // Implement delete logic here, such as calling an API or updating local state
  };

  return (
    <div>
      {data?.map((promo) => (
        promo?.lockUpdateBy?.id && promo?.lockUpdateBy?.role && (
          <div key={promo._id} className='tope-customer-com-main' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            {/* Shop Name */}
            <div className='second-row-customer' style={{ cursor: 'pointer', width: '10%' }}>
              <div className='top-custorm-width'>
                <h5 className='paragph-heading'>Shop Name</h5>
                <p className='h5-class-top-class'>
                  {promo?.shopName}
                </p>
              </div>
            </div>

            {/* Shop Open/Closed */}
            <div className='div-colm-order' style={{ width: '10%' }}>
              <p className='paragph-heading' style={{ fontSize: 11 }}>Shop On</p>
              <p className='h5-class-top-class' style={{ fontWeight: 400 }}>
                {promo?.isOpen ? 'Open' : 'Closed'}
              </p>
            </div>

            {/* Shop Price */}
            <div className='div-colm-order' style={{ width: '10%' }}>
              <p className='paragph-heading' style={{ fontSize: 11 }}>Shop Price</p>
              <p className='h5-class-top-class' style={{ fontWeight: 400 }}>
                {promo?.cost} AED
              </p>
            </div>

            {/* Shop Timing */}
            <div className='div-colm-order' style={{ width: '60%' }}>
              <p className='paragph-heading' style={{ fontSize: 11 }}>Shop Timing</p>
              <div className='paragph-heading' style={{ fontWeight: 400 }}>
                {renderTiming(promo?.timing)} {/* Render the timing details */}
              </div>
            </div>

            {/* Actions (Delete button can be added here if needed) */}
            <div className='div-colm-order' style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FaEdit  style={{ cursor: 'pointer',color:'#747EEF',fontSize:25 }} onClick={() => handleDelete(promo._id)} />
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default GetAllShop;
