import AgentSidebar from 'agentfolder/sidebar/agentSideBar'
import React, { useEffect, useState } from 'react'
import LOGO from '../../assets/images/app.logo.jpeg';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './app.css'
import { useLocation } from 'react-router-dom';
const AgentEditScree = () => {
  const location = useLocation();
  const { data } = location.state || {};
    const [username,setusername] = useState('')
    const [name,setname] = useState('')
    const [passsword,setpassowrd] = useState('')
    const [repassowrd,setRepassowrd] = useState('')
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
    <div className='div-Dashbaord'>
      <div className='row-div-admin-dashboard'>
    <h1 className='h1-all-heading'>Agent Edit Profile </h1>
    
    
    </div>
    <div className="dashboard">
          <div className='styles-div'>
            <div className="chart-container">
              <div className='flex' style={{justifyContent:'space-between',}}>
                <div className='flex' style={{width:'50%',alignItems:'center'}}>
             
             <h2 className='main-heading2'>Edit Details</h2>
                </div>
                <div className='flex' style={{width:'50%',alignItems:'center',justifyContent:'flex-end'}}>

                </div>
                
                </div>
               
                <div className='styles-div' style={{ width: '97%',paddingTop:30,display:'flex',justifyContent:'space-between',margin:'auto',gap:40}}>
               <div className='first-colm-form-editor'> 
               <label className="h5-heaidng-invouice" >Username:</label>
            <input  value={username} onChange={(e) => setusername(e.target.value)} type='text' placeholder='Title' style={{borderBottom:'1px solid black',marginBottom:30,paddingTop:9}}/>

            <label className="h5-heaidng-invouice" >Password:</label>
            <input  value={passsword} onChange={(e) => setpassowrd(e.target.value)} type='password' placeholder='Password' style={{borderBottom:'1px solid black',marginBottom:30,paddingTop:9}}/>
                 </div>
               <div className='first-colm-form-editor'>
               <label className="h5-heaidng-invouice" >Name:</label>
            <input  value={name} onChange={(e) => setname(e.target.value)} type='text' placeholder='Title' style={{borderBottom:'1px solid black',marginBottom:30,paddingTop:9}}/>

            <label className="h5-heaidng-invouice" >Re-Enter Password</label>
            <input  value={repassowrd} onChange={(e) => setRepassowrd(e.target.value)} type='password' placeholder='Password' style={{borderBottom:'1px solid black',marginBottom:30,paddingTop:9}}/>
                 
                 </div>
          </div>
          <div style={{display:'flex',justifyContent:'end'}}>
          <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Save' height={35} width={'20%'} />
          </div>

              </div>

            </div>
            <div className='styles-div-text'>
            <div className="chart-container" style={{textAlign:'center'}}>
                <div className='height-image-edit-profile-edit'>
                <img src={LOGO} style={{width:'77%',height:'80%',objectFit:'cover',borderRadius:'50%'}}/>
                </div>
               
                <h5 style={{textDecoration:'underline',cursor:'pointer'}}>Edit Profile Picture</h5>
                </div>
         </div>
          </div>
    </div>
    </div>
  )
}

export default AgentEditScree