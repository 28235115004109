import React, { useState } from 'react';
import './app.css';
import PopupCompanies from './PopupCompanies';
import ImageProo from '../../../../assets/images/app.logo.jpeg';
import InvoicePopup from '../InvoicePopup';

const CompaniesCom = ({ item,name, textpopupBtn, paymnet, spent, orderDate, Completed, show, Service, Loc, vist, orderId, orderTime, status, ImagePro }) => {
  const [modalShow, setModalShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const handleViewDocumentsClick = () => {
    setModalShow(true);
  };
console.log('ImagePro',ImagePro)
  const handleViewInvoice = () => {
    setModalShow(false);
    setInvoiceShow(true);
  };

  return (
    <div className="tope-customer-com-main">
      <div
        className="second-row-customer"
        style={{ cursor: 'pointer' }}
        onClick={handleViewDocumentsClick}
      >
        <img
        src={
          ImagePro?.includes('/media/image/cover-image.jpeg') 
            ? ImageProo 
            : ImagePro || ImageProo
        }
          className="image-pro-companies"
          style={{
            boxShadow: '0px 0px 3px 0px #333',
            borderRadius: '21%',
          }}
          alt="Company"
        />
        <div className="top-custorm-width">
          <h5 className="h5-class-top-class">{name}</h5>
          <p className="paragprh-customer-com">{vist}</p>
        </div>
      </div>
      {show && (
        <div
          className="second-row-customer"
          style={{ paddingTop: 16, cursor: 'pointer' }}
          onClick={handleViewDocumentsClick}
        >
          <p className="paragprh-customer-com">Status</p>
          <p className="heading-sub-customer-com">{Completed}</p>
        </div>
      )}
      <PopupCompanies
        show={modalShow}
        onHide={() => setModalShow(false)}
        popupData={item}
        textpopupBtn={textpopupBtn}
        onViewInvoice={handleViewInvoice}
      />
     
        <InvoicePopup
              showFields={true}
        show={invoiceShow}
        onClose={() => setInvoiceShow(false)}
        invoiceData={item}
      />
      <div
        className="second-row-customer"
        style={{
          paddingTop: show ? 5 : 15,
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={handleViewDocumentsClick}
      >
        <p className="paragprh-customer-com">Total Spent</p>
        <p className="heading-sub-customer-com">
          {spent} <span style={{ fontSize: 9 }}>AED</span>
        </p>
      </div>
    </div>
  );
};

export default CompaniesCom;
