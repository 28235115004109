import React, { useState } from 'react';
import ImagePro from '../../../assets/images/app.logo.jpeg';
import { HiOutlineLocationMarker } from "react-icons/hi";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './Pending.css';
import { BsThreeDotsVertical } from "react-icons/bs";
import Popup from './Popup';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate, formatDateconvert, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const PendingComp = ({name, date, time, ViewDocument, show, iconshow, TextPopupbtnLast, location, email, valNumber, Ceo, showBtn, document, RejectHandle, AcceptHandle, TextpopupbtnHandle}) => {
  const [modalShow, setModalShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [loading, setLoading] = useState({ accept: false, reject: false });
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const handleViewDocumentsClick = () => {
    setPopupData({ name, date, time, location, email, valNumber, Ceo, document });
    setModalShow(true);
  };

  const handleAcceptClick = async () => {
    setLoading(prev => ({ ...prev, accept: true }));
    try {
      await AcceptHandle();
      setFeedbackMessage('Accepted successfully!');
    } catch (error) {
      setFeedbackMessage('Failed to accept.');
    } finally {
      setLoading(prev => ({ ...prev, accept: false }));
      setShowFeedback(true);
    }
  };

  const handleRejectClick = async () => {
    setLoading(prev => ({ ...prev, reject: true }));
    try {
      await RejectHandle();
      setFeedbackMessage('Rejected successfully!');
    } catch (error) {
      setFeedbackMessage('Failed to reject.');
    } finally {
      setLoading(prev => ({ ...prev, reject: false }));
      setShowFeedback(true);
    }
  };

  return (
    <div className='main-pending-colm'>
      <div className='second-row-customer'>
        <img className='image-pending-css' src={ImagePro} />
        <div className='top-custorm-width' style={{position:'relative'}}>
          <h5 className='h5-class-top-class'>{name}</h5>
          <p className='paragprh-customer-com'>{formatDate(date)} At {formatTimeInTimezone(time)}</p>
          {iconshow && <BsThreeDotsVertical className='threedor-icon' />}
        </div>
      </div>
      <div className='second-row-customer' style={{justifyContent:'start', gap:4, paddingTop:10, paddingBottom:5}}>
        <HiOutlineLocationMarker style={{color:'black'}} />
        <p className='paragraph-text-p'>{location}</p>
      </div>
      <div style={{paddingTop:10, cursor:'pointer'}} onClick={handleViewDocumentsClick}>
        <ButtonDahbaord textButton={ViewDocument} height={32} width={'100%'} />
      </div>
      <Popup
        showBtn={showBtn}
        email={email}
        textpopupBtn={TextPopupbtnLast}
        HandleLogout={TextpopupbtnHandle}
        show={modalShow}
        onHide={() => setModalShow(false)}
        popupData={popupData}
      />
      {show && (
        <div className='second-row-customer' style={{paddingTop:10, paddingBottom:5}}>
          <ButtonDahbaord
            handleclick={handleRejectClick}
            textButton={loading.reject ? <CircularProgress size={17} color="inherit" /> : "Reject"}
            height={32}
            width={"48%"}
            disabled={loading.reject} // Disable button while loading
          />
          <ButtonDahbaord
            handleclick={handleAcceptClick}
            textButton={loading.accept ? <CircularProgress size={17} color="inherit" /> : "Accept"}
            height={32}
            width={"48%"}
            background='purple'
            disabled={loading.accept} // Disable button while loading
          />
        </div>
      )}
      {showFeedback && (
        <Popup
          showBtn={true}
          textpopupBtn={feedbackMessage}
          show={showFeedback}
          onHide={() => setShowFeedback(false)}
        />
      )}
    </div>
  );
}

export default PendingComp;
