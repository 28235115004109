import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import './app.css';
import { AiOutlineClose } from "react-icons/ai";
import { PiChatsFill } from "react-icons/pi";
import img1 from '../../assets/images/LatestReView.png';
import img2 from '../../assets/images/acceptorder.png';
import img3 from '../../assets/images/avarage.png';
import bell from '../../assets/images/Bell.png';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import DashbaordImgtext from 'components/latestOrder/DashbaordImgtext';
import TopCustomers from 'admin/components/dashboardcom/TopCustomers';
import TotalSalesChart from 'admin/components/dashboardcom/TotalSaleschart';
import TopCampanies from 'admin/components/dashboardcom/TopCampanies';
import { Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

function AdminDashbaord() {
  const [loading, setLoading] = useState(true);
  const [topCompanies, setTopCompanies] = useState([]);
  const [topCustomer, setTopCustomer] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const stats = useSelector(state => state.stats);

  useEffect(() => {
    window.scrollTo(0, 0);
   
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const companyUrl = 'https://backend.washta.com/api/admin/TopCompanies';
      const customerUrl = 'https://backend.washta.com/api/admin/TopCustomers?limit=6';
      
      try {
        const [companyResponse, customerResponse] = await Promise.all([
          axios.get(companyUrl, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(customerUrl, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);
        setTopCompanies(companyResponse.data?.data || []);
        setTopCustomer(customerResponse.data?.data || []);
        localStorage.setItem('topcustomerData', JSON.stringify(customerResponse.data?.data));
        localStorage.setItem('topcompanies', JSON.stringify(companyResponse.data?.data));
        
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          navigate('/');
        } 
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='row-div-admin-dashboard'>
          <h1 className='h1-all-heading width-asd'>Dashboard</h1>
          <div className='bell-div-css' style={{ gap: 5 }}>
           
            <PiChatsFill
              onClick={() => navigate('/super-admin-dashboard/Chats-screen')}
              style={{ fontSize: 27, color: "#747EEF", cursor: 'pointer' }}
            />
          </div>

        </div>
        <div className="dashboard">
          <div className='styles-div'>
            {loading ? (
              <div className='spinner-container'>
                <Spinner  style={{color:'#747EEF'}} loading={loading} size={50} />
              </div>
            ) : (
              <>
                <TotalSalesChart />
                <div className="stats" style={{ gap: 20 }}>
                  <DashbaordImgtext heading='Total Orders' value={stats?.totalNumberOfOrders} img={img1} />
                  <DashbaordImgtext heading='Cancelled Orders' value={stats?.totalCancelledOrders} img={img2} />
                  <DashbaordImgtext heading='Average Sales' value={stats?.averageMonthlySales || stats?.averageDailySales} img={img3} />
                </div>
                <div className="stats" style={{ gap: 20, justifyContent: 'flex-start' }}>
                  {stats?.totalRevenue !== undefined && (
                    <DashbaordImgtext heading='Total Revenue' value={stats?.totalRevenue} img={img1} />
                  )}
                  <DashbaordImgtext heading='Completed Orders' value={stats?.totalCompletedOrders || stats?.totalAcceptedOrders} img={img1} />
                </div>
                <TopCustomers data={topCustomer} />
              </>
            )}
          </div>
          <div className='styles-div-text'>
            <TopCampanies data={topCompanies} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashbaord;
