import AdminSidebar from 'admin/sidebar/AdminSidebar';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect, useState } from 'react';
import { GoDotFill } from "react-icons/go";
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../assets/images/deleteIcon.png';
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const CustomerDetails = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const [carData, setCarData] = useState([]);
    const [bookings, setBookings] = useState([]);
    const navigation = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchCustomerData = async () => {
            const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
            const vehiclesApiUrl = `https://backend.washta.com/api/admin/vehiclesByCustomer/?customerId=${item?.customerId?._id}`;
            const bookingsApiUrl = `https://backend.washta.com/api/admin/customer/${item?.customerId?._id}`;

            try {
                // Fetch both carData and bookings in parallel
                const [vehiclesResponse, bookingsResponse] = await Promise.all([
                    axios.get(vehiclesApiUrl, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(bookingsApiUrl, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                setCarData(vehiclesResponse.data.data);
                setBookings(bookingsResponse.data.data.Bookings.reverse());
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchCustomerData();
    }, [item]);
    const handleDelete = async (bookingId) => {
        const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage

        try {
            await axios.delete(`https://backend.washta.com/api/admin/bookings/${bookingId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Filter out the deleted booking from the state
            setBookings((prevBookings) => prevBookings.filter((booking) => booking._id !== bookingId));
        } catch (err) {
            console.log(err.message);
        }
    };
    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <AdminSidebar />
            </div>
            <div className='div-Dashbaord'>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'78%'}} >
                    <h1 className='h1-all-heading' style={{width:'30%'}}>{item.name}</h1>
                    <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
                        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Remove customer' height={35} width={'30%'} />
                    </div>
                </div>
                <div className="dashboard-customer-details">
                    <h2>Details</h2>    
                    <div className='div-customer-details-main'> 
                        <div className='popup-inputfeild-application addtion-adding'>
                            <p className='vat-number-api'>Number Plate:</p>
                            <input className='valt-number-input-feild' placeholder='text' value={item.vehicleId?.vehiclePlateNumber} readOnly />
                        </div>
                        <div className='popup-inputfeild-application addtion-adding'>
                            <p className='vat-number-api'>Location:</p>
                            <input className='valt-number-input-feild' placeholder='text' value={item?.location?.text || "no location"} readOnly />
                        </div>
                        <div className='popup-inputfeild-application addtion-adding'>
                            <p className='vat-number-api'>Email:</p>
                            <input className='valt-number-input-feild' placeholder='text' value={item?.customerId?.email} readOnly />
                        </div>
                        <div className='popup-inputfeild-application addtion-adding'>
                            <p className='vat-number-api'>Phone Number:</p>
                            <input className='valt-number-input-feild' placeholder='text' value={item?.customerId?.phone} readOnly />
                        </div>
                    </div>
                </div>
                <div className="dashboard-customer-details">
                    <h2>Registered Vehicles</h2>    
                    <div className='div-customer-details-main'> 
                        {carData?.map((item) => (
            <div 
                key={item?._id} 
                className={`popup-inputfeild-application addtion-adding ${item.isSelected ? 'active-vehicle' : ''}`} 
                style={{
                    display: 'block', 
                    flex: '1 1 calc(33.33% - 16px)', 
                    border: item.isSelected ? '2px solid #747EEF' : '1px solid #ccc',
                    backgroundColor: item.isSelected ? '#f0f0ff' : '#fff',
                }}
            >
                <p className='vat-number-api' style={{fontWeight:'500'}}>
                    {item?.vehicleManufacturer + " " + item?.vehicleName}
                </p>
                <div className='receipt-footer' style={{justifyContent:'start', gap: 5, paddingTop: 5}}>
                    <p className='vat-number-api' style={{width:'auto'}}>
                        {item?.vehicleType}
                    </p>
                    <p className='vat-number-api' style={{display:'flex', alignItems:'center'}}>
                        <span><GoDotFill style={{fontSize: 10}} /></span>{item?.vehiclePlateNumber}
                    </p>
                </div>
            </div>
        ))} 
                    </div>
                </div>
                <div className="dashboard-customer-details">
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}} >
                        <h2>Job Bookings</h2> 
                        <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
                            <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick={() => navigation('/super-admin-dashboard/customers/all-customers/customer-details/view-all-bookings', { state: { item: bookings } })} textButton='View all' height={30} width={'16%'} />
                        </div>
                    </div>  
                    <div className='div-customer-details-main' style={{gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))'}}> 
                        {bookings.slice(0, 6).map((item) => (
                            <div key={item?.id} className='booking-div-customer-div' style={{display:'block',flex: '1 1 calc(25% - 16px)'}}>
                                <h5 className='h5-class-top-class'>{"WS-" + item?._id.slice(0,15)}</h5>
                                <p className='paragprh-customer-com'>{formatDate(item?.date) + " At " + formatTimeInTimezone(item?.date) }</p>
                                <img src={DeleteIcon} className='image-abousulte-delete' onClick={() => handleDelete(item._id)}/>
                                <div className='colm-div-customer-com' style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingTop:15}}>
                                    <h5 className='paragraph-text-p' style={{fontSize:12}}>Status</h5>
                                    <h5 className='h5-class-top-class'>{item?.status}</h5>
                                </div>
                                <div className='colm-div-customer-com' style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingTop:5}}>
                                    <h5 className='paragraph-text-p' style={{fontSize:12}}>Total Spent</h5>
                                    <h5 className='h5-class-top-class'>{item?.cost}<span style={{ fontSize: 9 }}> AED</span></h5>
                                </div>
                            </div>
                        ))} 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerDetails;