import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../assets/images/deleteIcon.png';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import axios from 'axios';

const ViewAllBooking = () => {
    const location = useLocation();
    const { item } = location.state || [];
    const [bookings, setBookings] = useState(item || []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleDelete = async (bookingId) => {
        const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage

        try {
            await axios.delete(`https://backend.washta.com/api/admin/bookings/${bookingId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Filter out the deleted booking from the state
            setBookings((prevBookings) => prevBookings.filter((booking) => booking._id !== bookingId));
        } catch (err) {
            console.log(err.message);
        }
    };

    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <AdminSidebar />
            </div>
            <div className='div-Dashbaord width'>
                <h1 className='h1-all-heading'>{item?.name}</h1>
                <div className="dashboard">
                    <div className="dashboard-customer-details">
                        <h2>Job Bookings</h2>
                        <div className='div-customer-details-main' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))' }}>
                            {bookings.slice(0, 6).map((booking) => (
                                <div className='receipt-footer' key={booking._id} style={{ gap: 12 }}>
                                    <div className='booking-div-customer-div paddingRight' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 0 }}>
                                        <div>
                                            <h5 className='h5-class-top-class'>{booking._id}</h5>
                                            <p className='paragprh-customer-com'>{formatDate(booking.date) + " At " + formatTimeInTimezone(booking.date)}</p>
                                        </div>
                                        <div>
                                            <h5 className='paragraph-text-p'>Status</h5>
                                            <h5 className='h5-class-top-class'>{booking.status}</h5>
                                        </div>
                                        <div>
                                            <h5 className='paragraph-text-p'>Total Spent</h5>
                                            <h5 className='h5-class-top-class'>{booking.cost} <span style={{ fontSize: 9 }}>AED</span></h5>
                                        </div>
                                    </div>
                                    <div className='view-delete-btn' onClick={() => handleDelete(booking._id)}>
                                        <img src={DeleteIcon} className='image-delete-view-details' alt="Delete" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewAllBooking;
