
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Logo from '../../assets/images/app.logo.jpeg'
import NotificationCom from 'components/notification/NotificationCom'
import AgentSidebar from 'agentfolder/sidebar/agentSideBar'
const AgentNotification = () => {
  const [data,setData] = useState([])
  const [loading, setLoading] = useState(true); // Loading state

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = 'https://backend.washta.com/api/seller/Notifications';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data?.data.reverse() || []); // Use optional chaining
    } catch (err) {
      console.error('Error fetching notifications:', err.message);
    } finally {
      setLoading(false); // Ensure loading is stopped
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Agent Notifications</h1>
    <div className="dashboard">
    <div className='styles-div-order'>
<div className='invoice-border'>
<div className='chart-colm-div-heading'> 
          <h2 className='main-heading2' style={{paddingLeft:0}}>Today</h2>
          </div>
          <div className='row-details-order'>
          {loading
  ? Array.from({ length: 5 }).map((_, index) => (
      <NotificationCom key={index} isLoading={true} />
    )) :
<div style={{width:'100%'}}>
            {data?.map((item)=>(
              <div key={item._id} style={{width:'100%',marginBottom:10}}>
                <NotificationCom Name={item?.notification?.title} notificationMessages={item?.notification?.body} Date={item.createdAt} ImageNot={item.img || Logo}/>
                </div>
            ))}
            </div>}
            
            </div>
          </div>
          <div className='invoice-border'>
<div className='chart-colm-div-heading'> 
          <h2 className='main-heading2' style={{paddingLeft:0}}>Yesterday</h2>
          </div>
          <div className='row-details-order'>
          {loading
  ? Array.from({ length: 5 }).map((_, index) => (
      <NotificationCom key={index} isLoading={true} />
    )) :
<div style={{width:'100%'}}>
            {data?.map((item)=>(
              <div key={item._id} style={{width:'100%',marginBottom:10}}>
                <NotificationCom Name={item?.notification?.title} notificationMessages={item?.notification?.body} Date={item.createdAt} ImageNot={item.img || Logo}/>
                </div>
            ))}
            </div>}
            
            </div>
          </div>
          </div>
          
    </div>
    </div>
    </div>
  )
}

export default AgentNotification