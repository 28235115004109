import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { AiOutlineClose } from "react-icons/ai";
import { PiChatsFill } from "react-icons/pi";
import bell from '../../assets/images/Bell.png'
import DefulatImg from '../../assets/images/app.logo.jpeg'
import { Popover, Typography } from 'antd';
import LatestReview from '../../assets/images/latestreviews.png'
import AgentSidebar from 'agentfolder/sidebar/agentSideBar';
import { Skeleton } from '@mui/material';
import NoDataImage from '../../assets/images/nodataImg.png'; // Add the path to your image here
import LOGO from '../../assets/images/app.logo.jpeg';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import ReviewSummary from 'pages/clientDashbaord/dashboard/ratingandReviews/ReviewSummary';
import RequestActivity from './RequestActivity';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function AgentDashbaord() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      setLoading(true)
      const token = localStorage.getItem('agentToken');
      const apiUrl = 'https://backend.washta.com/api/agent/support?New=true';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.log(err.message);
      }finally{
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  const naviagtion = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
    const ratings = {
            1: 150,
            2: 70,
            3: 30,
            4: 15,
            5: 8,
          };
          const averageRating = 4.5;
      const totalReviews = 273;
      const recommendationPercentage = 88;
  const filteredData = [{id:1,username:'asdas',email:'easd',updatedAt:20,RequestId:"123123"}]
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
   
    <div className='div-Dashbaord'>
    <div className='row-div-admin-dashboard'>
          <h1 className='h1-all-heading width-asd'>Agent Dashboard</h1>
          <div className='bell-div-css' style={{ gap: 5 }}>
            <img
              onClick={()=>naviagtion('/agent-dashboard/agent-notification')}
              src={bell}
              style={{ width: '5%', objectFit: 'contain', cursor: 'pointer' }}
            />
            <img onClick={()=>naviagtion('/agent/agent-edit-profile')} src={DefulatImg} style={{ width: '7%', objectFit: 'contain', cursor: 'pointer',borderRadius:5,boxShadow:'rgb(191 184 184) 0px 0px 2px 2px'}} />
          </div>

          <Popover
            content={
              <div className='row-div-admin-dashboard'>
                <a>Notifications</a>
                <AiOutlineClose onClick={hide} />
              </div>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
          </Popover>
        </div>
        <div className="dashboard">
          <div className='styles-div'>
            <div className="chart-container">
              <div className='flex' style={{justifyContent:'space-between',}}>
                <div className='flex' style={{width:'50%',alignItems:'center'}}>
               
                <img
              src={LatestReview}
              style={{ width: '7%', objectFit: 'contain', cursor: 'pointer' }}
            />
             <h2 className='main-heading2'>Requests</h2>
                </div>
             
                </div>
                <div className='styles-div' style={{ width: '100%' }}>
            {loading ? (
              Array.from(new Array(10)).map((_, index) => (
                <div key={index} style={{ background: '#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px', marginTop: 20 }}>
                  <Skeleton variant="rectangular" width={48} height={53} style={{ marginRight: '10px', borderRadius: '8px', background: '#747eef9c' }} />
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                </div>
              ))
            ) : data?.length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            ) : (
              data?.map((item) => (
                <div className='main-invoice-div' style={{ marginTop: 15,cursor:'pointer' }} key={item.id} onClick={()=>naviagtion('/agent/agent-chats',{ state: { chatdata: item } })}>
                  <div className='row-invoice' style={{width:'100%'}}>
                    <div className='row-invoice' style={{ justifyContent: 'flex-start',width:'100%' }}>
                     <div className='div-text-image-immer-text' style={{ width: '10%',paddingLeft:0 }}> <img src={item?.avatar||LOGO} style={{ width: '100%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%',height:'3em',objectFit:'cover' }} /> </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Username</h5>
                        <p className='paragph-heading'>{item?.user?.username}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Title</h5>
                        <p className='paragph-heading'>{item.title}</p>
                      </div>
                      
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Date</h5>
                        <p className='paragph-heading'>{formatDate(item?.updatedAt) } || {formatTimeInTimezone(item?.updatedAt)}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '30%' }}>
                        <h5 className='h5-heaidng-invouice'>Request Status</h5>
                        <p className='paragph-heading'>{item.requestStatus}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '20%' }}>
                        <h5 className='h5-heaidng-invouice'>Is Joined</h5>
                        <p className='paragph-heading'>{item.isSomeOneConnected ? 'Yes' : 'No'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        
              </div>

              {/* <div className="responsive-container">
            
              </div> */}
            </div>
            <div className='styles-div-text'>
            <ReviewSummary text="Rating"  ratings={ratings}
        averageRating={averageRating}
        totalReviews={totalReviews}
        recommendationPercentage={recommendationPercentage} />
        {/* <RequestActivity/> */}
         </div>
          </div>
         
        </div>
      </div>
    
  );
}

export default AgentDashbaord;
