// import React, { createContext, useContext, useEffect, useState } from 'react';
// import io from 'socket.io-client';

// const SocketContext = createContext();

// export const useSocket = () => useContext(SocketContext);

// export const SocketProvider = ({ children }) => {
//   const [socket, setSocket] = useState(null);

//   useEffect(() => {
//     const newSocket = io('https://backend.washta.com/', { 
//       transports: ['websocket'],
//     });
//     setSocket(newSocket);

//     console.log('Socket connected:', newSocket);

//     return () => {
//       console.log('Cleaning up socket...');
//       if (newSocket) {
//         newSocket.close();
//       }
//     };
//   }, []);

//   return (
//     <SocketContext.Provider value={socket}>
//       {children}
//     </SocketContext.Provider>
//   );
// };

// // AgentChatSupport component remains the same
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// Create a context to hold the socket instance
const SocketContext = createContext();

// Custom hook to use the socket instance from the context
export const useSocket = () => {
  return useContext(SocketContext);
};

// SocketProvider component to manage socket connection and provide it to children
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the socket connection when the component mounts
    const newSocket = io('https://backend.washta.com/', {
      transports: ['websocket'], // Use websocket as the transport method
      reconnection: true,         // Allow reconnection if the socket disconnects
      reconnectionAttempts: 5,    // Number of reconnection attempts
    });

    // Handle successful connection
    newSocket.on('connect', () => {
      console.log('Socket connected:', newSocket.id); // Logs socket ID when connected
    });

    // Handle connection error
    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error.message);
    });

    // Handle disconnection
    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason); // Logs reason for disconnection
    });

    // Set the socket instance in state
    setSocket(newSocket);

    // Clean up when the component unmounts
    return () => {
      console.log('Socket connection closed');
      if (newSocket) newSocket.close();
    };
  }, []);

  // Provide the socket instance to all children components
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
