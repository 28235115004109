import AllCustomerCom from 'admin/components/jobhistory/AllCustomerCom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const AllCustomers = () => {
    const location = useLocation();
    const { item } = location.state || {};
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
   

  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Customers </h1>
    <div className="dashboard">    
      <div className='styles-div addtionwidth-setting '>
        {item?.map((item)=>(
            <div key={item.id}>
                <AllCustomerCom Imaag={item?.customerId?.avatar}
                item={item}
                onclick={()=> navigator('/super-admin-dashboard/customers/all-customers/customer-details',{ state: { item: item } })}
                 status={item.status   } 
                 name={item?.customerId?.username}
                payment={item.cost }
                date={item?.createdAt }/>
            </div>

        ))}
   
      
      </div>
    </div>
    </div>
    </div>
  )
}

export default AllCustomers