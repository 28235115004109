import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa'; // Importing a trash icon from react-icons
import Switch from 'react-switch'; // Importing the Switch component

const AllGetPromoCode = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/promoCode';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
        console.log('response.data.data.',response.data.data)
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  const handleToggle = async (id, isActive) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/promoCode/${id}`;
    const newStatus = !isActive; // Toggle status

    try {
     const response = await axios.patch(apiUrl, 
        { isActive : newStatus }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('responseresponse',response.data)
      // Update local state to reflect the change
      setData(prevData =>
        prevData.map(item =>
          item._id === id ? { ...item, isActive: newStatus } : item
        )
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/promoCode/${id}`;

    try {
      await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the deleted promo code from the state
      setData((prevData) => prevData.filter((promo) => promo._id !== id));
    } catch (err) {
      console.log('Error deleting promo code:', err.message);
    }
  };

  return (
    <div>
      {data.map((promo) => (
        <div key={promo._id} className='tope-customer-com-main' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div className='second-row-customer' style={{ cursor: 'pointer', width: '20%' }}>
            <div className='top-custorm-width'>
              <h5 className='paragph-heading'>PromoCode Name</h5>
              <p className='h5-class-top-class'>{promo.promoCode}</p>
            </div>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Discount Type</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{promo?.Discounttype}</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Give To All</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{promo.giveToAll ? 'Yes' : 'No'}</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Start Time</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{formatDate(promo.duration.startTime) + " At " + formatTimeInTimezone(promo.duration.startTime) }</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>End Time</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{formatDate(promo.duration.endTime) + " At " + formatTimeInTimezone(promo.duration.endTime) }</p>
          </div>
          <div className='div-colm-order' style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Switch
              onChange={() => handleToggle(promo._id, promo.isActive)}
              checked={promo.isActive}
              onColor="#747EEF"
              offColor="#bcbcbc"
              handleDiameter={17}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={36}
            />
            <FaTrash
              onClick={() => handleDelete(promo._id)}
              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllGetPromoCode;
