import React, { useEffect, useRef, useState } from 'react';
import '../../admin/adminchats/ChatApp.css';
import { LuSend } from "react-icons/lu";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import Logo from '../../assets/images/image 1.png';
import AppLogo from '../../assets/images/app.logo.jpeg';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSocket } from '../../Socket'; // Adjust the path accordingly
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import { Button, Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';

const interactionHistory = [
  {
    status: 'Resolved',
    description: "Customer Didn't show up",
    service: 'Tire Wash',
    agent: 'Franc Lee',
    date: '13/02/24',
    time: '15:35',
  },
  // Add more history items here
];

const AgentChatScreen = () => {
  const location = useLocation();
  const { chatdata } = location.state || {};
  const [messageText, setMessageText] = useState('');
  const [activeConversation, setActiveConversation] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({ messages: [] });
  const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  const navigation = useNavigate();
  const socket = useSocket();
  const [user, setUsers] = useState('')
  const [resovledchat,setresolvedChat] = useState([])
  const [showStatusButtons, setShowStatusButtons] = useState(false);
console.log('resovledchat',resovledchat)
  const handleStatusSelection = async (status) => {
      const payload = {
          status: status,
      };

      try {
          const response = await axios.patch(
              `https://backend.washta.com/api/agent/endChat/${selectedConversation?._id}`, // Assuming selectedConversation has an 'id' property
              payload,
              {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              }
          );
          toast.success('Sign in successful!');
          console.log(response.data); // Handle successful response
          setShowStatusButtons(false)
      } catch (error) {
          console.error(error); // Handle error
      }
  };

  const token = localStorage.getItem('agentToken');

   useEffect(() => {
  
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const token = localStorage.getItem('agentToken');
      const apiUrl = 'https://backend.washta.com/api/agent/support?New=false';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const conversations = response.data.data.reverse();
        setActiveConversation(conversations);

        if (chatdata) {
          const matchedConversation = conversations.find(conv => conv._id === chatdata._id);
          if (matchedConversation) {
            setSelectedConversation(matchedConversation);
            joinSocket(matchedConversation._id);
            fetchChatHistory(matchedConversation._id);
          } else {
            setSelectedConversation(chatdata);
            joinSocket(chatdata._id);
            fetchChatHistory(chatdata._id);
          }
        } else {
          const firstConversation = conversations[0];
          setSelectedConversation(firstConversation);
          joinSocket(firstConversation._id);
          fetchChatHistory(firstConversation._id);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();

    return () => {
      if (socket) {
        socket.emit('leave', { ticketId: selectedConversation._id });
      }
    };
  }, [chatdata]);
useEffect(()=>{
  const fetchData = async () => {
    const token = localStorage.getItem('agentToken');
    const apiUrl = 'https://backend.washta.com/api/agent/support?status=resolved';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const conversations = response.data.data.reverse();
      setresolvedChat(conversations); 
      
    } catch (err) {
      console.log(err.message);
    }
  };
  fetchData()
},[])
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const joinSocket = (ticketId) => {
    if (socket) {
      socket.emit('join', { ticketId });
    }
  };

  useEffect(() => {
    if (socket) {
      // Listen for messages from the agent or other events
      socket.on('message-receive-from-user', (message) => {
        console.log('Received message from agent:', message);
      });

      // Cleanup the event listener when the component unmounts
      return () => {
        socket.off('message-receive-from-agent');
      };
    }
  }, [socket]);
  

  const handleConversationClick = (conversation) => {
    if (socket) {
      socket.emit('leave', { ticketId: selectedConversation._id });
    }
    setSelectedConversation(conversation);
    joinSocket(conversation._id);
    fetchChatHistory(conversation._id);
  };

  const handleSendMessage = () => {
    if (messageText.trim() === '') return;

    const newMessage = {
      ticketId: selectedConversation._id,
      receiver: {
        id: selectedConversation.user?.id,
        username: selectedConversation.user?.username,
        role: "customer",
      },
      sender: {
        id: selectedConversation.connectedWith?.id,
        role: "agent",
        username: selectedConversation.connectedWith?.username,
      },
      message: messageText,
    };

    if (socket) {
      socket.emit('send-message-to-user', newMessage);
      console.log(newMessage)
      // Append the sent message to the chat history
      const sentMessage = {
        ticketId: newMessage.ticketId,
        sender: {
          role: "agent",
          id: newMessage.sender.id,
          username: newMessage.sender.username,
        },
        message: messageText,
        createdAt: new Date().toISOString(), // Use ISO string for uniformity
      };

      setChatHistory(prev => [...prev, sentMessage]);
      setMessageText(''); // Clear the input field
    } else {
      console.error('Socket is not connected.');
    }
  };
  const fetchChatHistory = async (ticketId) => {
    try {
      const response = await axios.get(`https://backend.washta.com/api/agent/chat/?ticketId=${ticketId}&skip=0`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setChatHistory(response.data.data.reverse());
    } catch (error) {
      console.error('Error fetching chat history:', error.message);
    }
  };

  const acceptButton = async () => {
    const token = localStorage.getItem('agentToken');
    try {
      const response = await axios.patch(
        `https://backend.washta.com/api/agent/support/${chatdata?._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigation('/agent/agent-chat-support');
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="chat-app">
      <div className="active-conversations" style={{ marginTop: 0 }}>
        <div className="logo" style={{ width: '90%', cursor: 'pointer' }} onClick={() => navigation('/super-admin-dashboard')}>
          <img src={Logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </div>
        <h2 className='main-heading2 addtion-styling-text'>Active Conversations</h2>
        <div className='chat-active-conversation-scroll'>
          {activeConversation?.map((conv) => (
            <div
              key={conv._id}
              className={`conversation ${selectedConversation?._id === conv._id ? 'active' : ''}`}
              onClick={() => handleConversationClick(conv)}
            >
              <div className='second-row-customer'>
                <img className='image-pending-css' src={AppLogo} />
                <div className='top-custorm-width'>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <h5 className='h5-heaidng-invouice'>{conv?.user?.username}</h5>
                    <p  className='paragph-heading-status-heaing'>{conv?.requestStatus}</p>
                   </div>
                  <p className='paragph-heading'>{conv?.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="current-chat">
        <div className='row-invoice'>
          <h2 className='main-heading2'>{selectedConversation?.title}</h2>
          {selectedConversation?.requestStatus !== 'resolved' && selectedConversation?.requestStatus  !== 'rejected' && (
                <>
               <div style={{ width: '55%', display: 'flex', justifyContent: 'end', gap: 14 }}>
            {/* <ButtonDahbaord handleclick={''} textButton="End Chats" height={30} width={'45%'} background='purple' /> */}
            {!showStatusButtons ? (

                        <ButtonDahbaord handleclick={()=>setShowStatusButtons(true)} textButton="End Chats" height={30} width={'45%'} background='purple' />
                    ) : (
                        <Stack spacing={2} sx={{ mt: 2,}} style={{flexDirection:'row',gap:10}}>
                           <ButtonDahbaord handleclick={() => handleStatusSelection('ongoing')} textButton="On Going" height={30} width={'50%'} background='purple' />
                           <ButtonDahbaord editButton="#009B10" handleclick={() => handleStatusSelection('resolved')} textButton="Resolved" height={30} width={'50%'} background='purple' />
                           <ButtonDahbaord editButton="#ff2c2c" handleclick={() => handleStatusSelection('rejected')} textButton="Rejected" height={30} width={'50%'} background='purple' />
                           
                        </Stack>
                    )}
            {chatdata?.requestStatus === 'pending' && (
              <ButtonDahbaord handleclick={() => acceptButton()} textButton="Accept Chats" height={30} width={'55%'} />
            )}
          </div>
                </>
            )}
          
        </div>
       <div className="messages">
       {chatHistory?.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender?.role === 'customer' ? 'agent-message' : 'customer-message'}`}
          >
            <p>{message.message}</p>
            <span className='time-chat-application'>
              {`${formatDate(new Date(message.createdAt))} At ${formatTimeInTimezone(new Date(message.createdAt))}`}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
    </div>
             
         {selectedConversation?.requestStatus !== 'resolved' && selectedConversation?.requestStatus  !== 'rejected' && (
                <>
                  <div className="chat-input">
          <input
            type="text"
            placeholder="Type a message..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <button className='send-button-agent-send' onClick={handleSendMessage}>
            <LuSend style={{fontSize:16}} />
          </button>
        </div>
                </>
            )}
      </div>

            <div className="interaction-history">
        <h2 className='main-heading2' style={{paddingBottom:20}}>Interaction History</h2>
         {resovledchat?.map((item, index) => (
                    <div key={index} className="history-item">
            <div className="status">{item.requestStatus}</div>
            <div className="heading-h5-later" style={{ paddingTop: 7 }}>{item?.user?.username}</div>
            <div className="details">
              <div className='colm-div-width'>
                <p className="paragprh-customer-com">Service:</p>
                <h5 className="h5-class-top-class">{item.title}</h5>
              </div>
              <div className='colm-div-width'>
                <p className="paragprh-customer-com">Date:</p>
                <h5 className="h5-class-top-class" style={{fontSize:10}}>{formatDate(item.endedAt)}  || {formatTimeInTimezone(item.endedAt)}</h5>
         </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentChatScreen;
