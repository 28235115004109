import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { IoCloseOutline } from 'react-icons/io5';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './app.css';
import axios from 'axios';

const PopupAgent = ({ show, onHide }) => {
  const [loading, setLoading] = useState(false); 
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    password: '',
    role: 'Select Role'
  });
const paylaod = {
  username: formData.username,
  fullname : formData.name,
  password: formData.password
}

  const [errors, setErrors] = useState({
    username: '',
    name: '',
    password: '',
    role: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: '' // Clear the error message when the user starts typing
    });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.username) {
      newErrors.username = 'Username is required';
      isValid = false;
    }

    if (!formData.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    if (formData.role === 'Select Role') {
      newErrors.role = 'Role is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


    const handleSubmit = async () => {
      if (validateForm()) {
        setLoading(true); // Start loading spinner
        try {
          const token = localStorage.getItem('accessTokenAdmin');
          const response = await axios.post(
            'https://backend.washta.com/api/admin/agent/Signup',
            paylaod,
            {
              headers: {
                Authorization: `Bearer ${token}`,  // Ensure there's a space after 'Bearer'
              },
            }
          );
          console.log('API Response:', response.data);
          onHide();
          window.location.reload()
        } catch (error) {
          console.error('Error submitting form:', error.response ? error.response.data : error.message);
        } finally {
          setLoading(false); // Stop loading spinner
        }
      }
    };


  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Add Washta Agent
        <p className='paragph-heading'>Create a new account</p>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onHide}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <label className='label-popup-seller'>Username <sup>*</sup></label>
          <input
          style={{borderBottom:'1px solid #c3c3c3'}}
            className='input-feild-popupagnet'
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
          />
          {errors.username && <Typography color="error" variant="body2">{errors.username}</Typography>}

          <label className='label-popup-seller' style={{ marginTop: 10 }}>Name <sup>*</sup></label>
          <input
          style={{borderBottom:'1px solid #c3c3c3'}}
            className='input-feild-popupagnet'
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <Typography color="error" variant="body2">{errors.name}</Typography>}

          <label className='label-popup-seller' style={{ marginTop: 10 }}>Password <sup>*</sup></label>
          <input
          style={{borderBottom:'1px solid #c3c3c3'}}
            className='input-feild-popupagnet'
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <Typography sx={20} color="error" variant="body2">{errors.password}</Typography>}

          <label className='label-popup-seller' style={{ marginTop: 10 }}>Role <sup>*</sup></label>
          <select
          style={{borderBottom:'1px solid #c3c3c3', padding: '10px 0px' }}
           
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="select"
          >
            <option value="Select Role">Select Role</option>
            <option value="Agents">Agents</option>
          </select>
          {errors.role && <Typography color="error" variant="body2">{errors.role}</Typography>}
        </div>
      </DialogContent>
      <DialogActions>
        <div className='row-div-admin-dashboard' style={{ width: '100%' }}>
          <ButtonDahbaord handleclick={onHide} textButton="Cancel" height={35} width={'30%'} background='transparent' />
          <ButtonDahbaord 
            handleclick={handleSubmit} 
            textButton={loading ? <CircularProgress size={24} color="inherit" /> : "Create"} 
            height={35} 
            width={'30%'} 
            background='purple' 
            disabled={loading} // Disable button while loading
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PopupAgent;
