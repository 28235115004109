// export const isAuthenticated = () => {
//   const accessToken = localStorage.getItem("accessToken");
//   const accessTokenAdmin = localStorage.getItem("accessTokenAdmin");

//   if (accessTokenAdmin && accessTokenAdmin !== "undefined" && accessTokenAdmin !== "null") {
//       return "admin";
//   } else if (accessToken && accessToken !== "undefined" && accessToken !== "null") {
//       return "user";
//   } else {
//       return null;
//   }
// };
export const isAuthenticated = () => {
    const accessToken = localStorage.getItem("accessToken");
    const accessTokenAdmin = localStorage.getItem("accessTokenAdmin");
    const agentToken = localStorage.getItem("agentToken");
  
    if (accessTokenAdmin && accessTokenAdmin !== "undefined" && accessTokenAdmin !== "null") {
      return "admin";
    } else if (accessToken && accessToken !== "undefined" && accessToken !== "null") {
      return "user";
    } else if (agentToken && agentToken !== "undefined" && agentToken !== "null") {
      return "agent";
    } else {
      return null;
    }
  };
  