import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord'
import React from 'react'
import DefulatIMg from '../../assets/images/app.logo.jpeg'
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics'
const AgentRemoveCom = ({img,name,username,date,role,click,}) => {
  return (
    <div className='tope-customer-com-main' style={{display:'flex',justifyContent:'space-between'}}>

       <div className='second-row-customer' style={{cursor:'pointer'}}>
        <img src={img || DefulatIMg} className='image-pro-companies' style={{ width: '100%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%',height:'3em',objectFit:'cover' }} />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{name}</h5>
        </div>
      </div>    
      <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Username</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{username}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Joining Date</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{formatDate(date)} At {formatTimeInTimezone(date)}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Role</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{role}</p>
  </div>
  <div className='div-colm-order'>
  <ButtonDahbaord  handleclick={click} textButton="Remove" height={35} width={'100%'} background='purple' />

  </div>
    </div>
  )
}

export default AgentRemoveCom