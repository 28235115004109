import React, { useEffect, useState } from 'react';
import ImgSingin from '../../assets/images/singup.png';
import Overly from '../../assets/images/overly.png';
import InputFeild from '../../components/Input/Input';
import { Button } from 'components';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import Header from 'pages/Home1/Header';
import Footer from 'components/Footer';
import AdminOtp from 'admin/AdminOtp';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LoginAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const navigate = useNavigate()
  const [showOTPComponent, setShowOTPComponent] = useState(false);
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleSignin = async () => {
    setLoading(true);
    try {
        const response = await axios.post('https://backend.washta.com/api/admin/login', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.data.status && response.data.code === 200) {
            const accessToken = response.data.data.accessToken;
            if (typeof window !== 'undefined') {
                localStorage.setItem('accessTokenAdmin', accessToken);
            }
            await AsyncStorage.setItem('accessTokenAdminUser', accessToken);

            toast.success('Sign in successful!');
            console.log('Successasdasd:', response.data);
            setShowOTPComponent(true);
        } else {
            toast.error('Sign in failed. Please try again.');
            console.error('Error: Invalid response', response.data);
        }
    } catch (error) {
        toast.error('Sign in failed. Please try again.');
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
};

  const HandleFunction = () => {
    const colmFirstAdd = document.querySelector('.colm-first.add');
    colmFirstAdd.classList.add('fade-out');
    handleSignin();
  };

  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='colm-first'> <img src={ImgSingin} alt="Logo" /> </div>
          <div className='colm-first add'>
            {showOTPComponent ? (
              <AdminOtp email={formData?.identifier} />
            ) : (
              <div className='innerDiv-form'>
                <h1 className='h1-heaing'>Welcome back! Admin </h1>
                <p className='paragraph-otp'> Boost your business by providing your services to customers. </p>
                <div className='gapmargin'> <InputFeild type='email' label="Email" placeholder="Enter your Email" onChange={(value) => handleChange('identifier', value)} /></div>
                <div className='gapmargin'> <InputFeild type='password' label="Password" placeholder="Enter your Password" onChange={(value) => handleChange('password', value)} /></div>
                <p className='paragraph-otp' style={{marginTop:'2em',cursor:'pointer'}} onClick={()=>navigate('/forgot-password',{ state: { item: 'admin' } })}> Forgot Password? </p>

                {/* <NavLink style={{color:'white',marginTop:15}} to='/forgot-password'> Forgot Password? </NavLink>  */}
                <Button onClick={HandleFunction} shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}>
                  {loading ? <Spinner animation="border" style={{ color: '#747EEF', width: '1.5em', height: '1.5em' }} size="sm" /> : 'Sign in'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  )
}

export default LoginAdmin;
