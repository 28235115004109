import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Switch from 'react-switch';
import '../servicetiming/ServiceTiming.css';
import { CiSearch } from "react-icons/ci";
import { Checkbox, FormControlLabel } from '@mui/material';
import DatePicker from 'react-date-picker';
import DefulatImg from '../../assets/images/app.logo.jpeg'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import AllGetPromoCode from './AllGetPromoCode';
const PromoCode = () => {
  const [value, onChange] = useState(new Date());
  const [endValue, setEndValue] = useState(new Date()); // Separate state for end date
  const [isSpecificProvider, setIsSpecificProvider] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [applyAtAll, setApplyAtAll] = useState(true);  // Initially apply to all customers
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [isActive, setIsActive] = useState(true); // State for the status toggle
 const [user, setuser] = useState([])
  const handleSwitchChange = () => {
    const newValue = !isSpecificProvider;
    setIsSpecificProvider(newValue);

    if (!newValue) {
      // Apply to all customers
      setSelectedProviders([]);
      setApplyAtAll(true);
    } else {
      // Apply to selected service providers
      setApplyAtAll(false);
    }
  };

  const handleSelectProvider = (provider) => {
    setSelectedProviders((prev) =>
      prev.some(p => p._id === provider._id) 
        ? prev.filter((p) => p._id !== provider._id) 
        : [...prev, provider]
    );
  };
  

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };
  const handleAcceptClick = async () => {
        const startTimeISO = value.toISOString();
    const endTimeISO = endValue.toISOString();
    const payload = {
      isActive,
      Discounttype: discountType,
      promoCode,
      discount,
         duration: {
        startTime: startTimeISO,
        endTime: endTimeISO,
      },
      giveTo: selectedProviders.length > 0 
      ? selectedProviders.map(provider => provider._id)  // Include only `customerId` in `giveTo`
      : [],
      giveToAll: applyAtAll
    };
  
    const token = localStorage.getItem('accessTokenAdmin');
  
    try {
      const response = await axios.post('https://backend.washta.com/api/admin/promocode', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  console.log(payload,'response',response.data)
  window.location.reload()
      // Handle success (if needed)
    } catch (error) {
      console.error('API Error:', error.response ? error.response.data : error.message);
      // Handle error response here, like showing an error message
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/customer';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
setuser(response.data.data.reverse());
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='width-settimg addtionwidth-setting'>
          <div className='receipt-row' style={{ alignItems: 'center' }}>
            <h1 className='h1-all-heading'>Promo Code</h1>
            <div className='receipt-row' style={{ gap: 20 }}>
              <p className='h5-class-top-class' style={{ fontSize: 16, fontWeight: 500 }}>Specific Service Provider</p>
              <Switch
                onChange={handleSwitchChange}
                checked={isSpecificProvider}
                onColor="#747EEF"
                offColor="#bcbcbc"
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={36}
              />
            </div>
          </div>
          <div className="dashboard">
            <div className="dashboard-customer-details" style={{ width: '100%' }}>
              <div className="receipt-row" style={{ paddingTop: 10 }}>
                <div style={{ width: '75%' }}>
                  <p className='paragprh-customer-com'>Provide Promo Codes to customers.</p>
                  <div style={{ marginTop: 15 }}>
                    <span>Customers:</span>
                    <span>
                      {applyAtAll
                        ? <span className="span-seleceted-servicetiming" style={{ marginRight: '10px' }}>All Selected</span>
                        : selectedProviders.map((p, index) => (
                          <span className="span-seleceted-servicetiming" key={index} style={{ marginRight: '10px' }}>
                            {p.username}
                          </span>
                        ))}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => isSpecificProvider && setShowPopup(true)}
                  className={isSpecificProvider ? 'btnactive' : 'btninactive'}
                  disabled={!isSpecificProvider}
                >
                  Select Customers
                </button>
              </div>

              <div className="form-group" style={{ marginTop: 30 }}>
                <label className="h5-heaidng-invouice">Select Discount Type</label>
                <select
                  value={discountType}
                  onChange={handleDiscountTypeChange}
                  className="select"
                >
                  <option value="" disabled>Please Select Discount Type</option>
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
                <div className="form-group" style={{ width: '90%' }}>
                  <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>Discount</label>
                  <input
                    type={discountType === 'percentage' ? 'number' : 'text'}
                    placeholder={discountType === 'percentage' ? '00.00' : 'AED'}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    style={{ paddingBottom: '10px', borderBottom: '1px solid' }}
                  />
                  <span className="label-popup-seller" style={{ fontWeight: '700', position: 'absolute', right: '42.5%' }}>
                    {discountType === 'percentage' ? '%' : 'AED'}
                  </span>
                </div>

                <div className="form-group" style={{ width: '90%' }}>
                  <label className="h5-heaidng-invouice" style={{paddingBottom:11}}>Promocode</label>
                  
                   <input
                    type='text'
                    placeholder='Promocode'
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    style={{ paddingBottom: '10px', borderBottom: '1px solid' }}
                  />
                </div>
              </div>
              
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
                <div className="form-group" style={{ width: '90%' }}>
                  <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>Start Duration Date</label>
                  <DatePicker onChange={onChange} value={value} />
                </div>

                <div className="form-group" style={{ width: '90%' }}>
                <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>End Duration Date</label>
                  <DatePicker onChange={setEndValue} value={endValue} />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '40px', marginTop: '20px' }}>
                <label className="label-popup-seller" style={{ paddingTop: 11, fontSize: '18px', paddingRight: '10px' ,paddingBottom:20}}>Status (Active / Discontinue)</label>
                <Switch
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                  onColor="#747EEF"
                  offColor="#bcbcbc"
                  handleDiameter={17}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={36}
                />
              </div>

                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <button className="btnactive" onClick={handleAcceptClick}>Promo Code Create</button>
              </div>
              <label className="label-popup-seller" style={{ paddingTop: 11, fontSize: '18px', paddingRight: '10px' }}>Get all Promo Codes</label>

              <AllGetPromoCode/>
            </div>
          </div>
        </div>
         <Modal
        title="Select Service Provider(s)"
        open={showPopup}
        onCancel={() => setShowPopup(false)}
        footer={null}
        centered
        style={{ borderRadius: 10 }}
      >
                      <div className='input-feild-dic-icon' style={{ marginTop: '0px', border: '1px solid #B3B3B3', borderRadius: '10px',width:'100%'}}>
                 <CiSearch className='icons-search-size' />
                 <input type="text" placeholder='Search by service provider' className='input-feild-class-job-history' />
               </div>
               <div style={{ height: '22em', overflow: 'scroll' }}>
                 {user?.map((provider) => (
                  <div className='tope-customer-com-main' style={{ display: 'flex', justifyContent: 'space-between', padding: '11px 9px',marginTop:'10px' }} key={provider._id}>
                    <div className='second-row-customer'>
                      <img src={provider.avatar || DefulatImg} className="image-pro-companies" style={{ width: '4em', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%',height:'3em',objectFit:'cover' }} />
                      <div className='top-custorm-width'>
                        <h5 className='h5-class-top-class'>{provider.username}</h5>
                        <p className='paragraph-text-p'>{provider.email}</p>
                      </div>
                    </div>
                    <Checkbox
                      checked={selectedProviders.some(p => p._id === provider._id)}
                      onChange={() => handleSelectProvider(provider)}
                    />
                  </div>
                ))}
              </div>
      </Modal>
      </div>
    </div>
  );
};

export default PromoCode;

// import AdminSidebar from 'admin/sidebar/AdminSidebar';
// import React, { useEffect, useState } from 'react';
// import { Modal } from 'antd';
// import Switch from 'react-switch';
// import '../servicetiming/ServiceTiming.css';
// import { CiSearch } from "react-icons/ci";
// import { Checkbox } from '@mui/material';
// import DatePicker from 'react-date-picker';
// import DefulatImg from '../../assets/images/app.logo.jpeg';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import axios from 'axios';
// import AllGetPromoCode from './AllGetPromoCode';

// const PromoCode = () => {
//   const [value, onChange] = useState(new Date());
//   const [endValue, setEndValue] = useState(new Date()); // Separate state for end date
//   const [isSpecificProvider, setIsSpecificProvider] = useState(false);
//   const [selectedProviders, setSelectedProviders] = useState([]);
//   const [showPopup, setShowPopup] = useState(false);
//   const [applyAtAll, setApplyAtAll] = useState(true);  // Initially apply to all customers
//   const [promoCode, setPromoCode] = useState('');
//   const [discount, setDiscount] = useState('');
//   const [discountType, setDiscountType] = useState('');
//   const [isActive, setIsActive] = useState(true); // State for the status toggle
//   const [user, setuser] = useState([]);

//   const handleSwitchChange = () => {
//     const newValue = !isSpecificProvider;
//     setIsSpecificProvider(newValue);

//     if (!newValue) {
//       // Apply to all customers
//       setSelectedProviders([]);
//       setApplyAtAll(true);
//     } else {
//       // Apply to selected service providers
//       setApplyAtAll(false);
//     }
//   };

//   const handleSelectProvider = (provider) => {
//     setSelectedProviders((prev) =>
//       prev.some(p => p._id === provider._id)
//         ? prev.filter((p) => p._id !== provider._id)
//         : [...prev, provider]
//     );
//   };

//   const handleDiscountTypeChange = (e) => {
//     setDiscountType(e.target.value);
//   };

//   const handleAcceptClick = async () => {
//     // Convert dates to ISO format
//     const startTimeISO = value.toISOString();
//     const endTimeISO = endValue.toISOString();

//     const payload = {
//       isActive,
//       Discounttype: discountType,
//       promoCode,
//       discount,
//       duration: {
//         startTime: startTimeISO,
//         endTime: endTimeISO,
//       },
//       giveTo: selectedProviders.length > 0 
//         ? selectedProviders.map(provider => provider._id)  // Include only `customerId` in `giveTo`
//         : [],
//       giveToAll: applyAtAll,
//     };

//     const token = localStorage.getItem('accessTokenAdmin');

//     try {
//       const response = await axios.post('https://backend.washta.com/api/admin/promocode', payload, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });
//      window.location.reload()

//     } catch (error) {
//       console.error('API Error:', error.response ? error.response.data : error.message);
//       // Handle error response here, like showing an error message
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     const fetchData = async () => {
//       const token = localStorage.getItem('accessTokenAdmin');
//       const apiUrl = 'https://backend.washta.com/api/admin/customer';

//       try {
//         const response = await axios.get(apiUrl, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setuser(response.data.data.reverse());
//       } catch (err) {
//         console.log(err.message);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className='mainDiv-all-sideBar'>
//       <div className='div-sidebar'>
//         <AdminSidebar />
//       </div>
//       <div className='div-Dashbaord'>
//         <div className='width-settimg addtionwidth-setting'>
//           <div className='receipt-row' style={{ alignItems: 'center' }}>
//             <h1 className='h1-all-heading'>Promo Code</h1>
//             <div className='receipt-row' style={{ gap: 20 }}>
//               <p className='h5-class-top-class' style={{ fontSize: 16, fontWeight: 500 }}>Specific Service Provider</p>
//               <Switch
//                 onChange={handleSwitchChange}
//                 checked={isSpecificProvider}
//                 onColor="#747EEF"
//                 offColor="#bcbcbc"
//                 handleDiameter={17}
//                 uncheckedIcon={false}
//                 checkedIcon={false}
//                 boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
//                 activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                 height={20}
//                 width={36}
//               />
//             </div>
//           </div>
//           <div className="dashboard">
//             <div className="dashboard-customer-details" style={{ width: '100%' }}>
//               <div className="receipt-row" style={{ paddingTop: 10 }}>
//                 <div style={{ width: '75%' }}>
//                   <p className='paragprh-customer-com'>Provide Promo Codes to customers.</p>
//                   <div style={{ marginTop: 15 }}>
//                     <span>Customers:</span>
//                     <span>
//                       {applyAtAll
//                         ? <span className="span-seleceted-servicetiming" style={{ marginRight: '10px' }}>All Selected</span>
//                         : selectedProviders.map((p, index) => (
//                           <span className="span-seleceted-servicetiming" key={index} style={{ marginRight: '10px' }}>
//                             {p.username}
//                           </span>
//                         ))}
//                     </span>
//                   </div>
//                 </div>
//                 <button
//                   onClick={() => isSpecificProvider && setShowPopup(true)}
//                   className={isSpecificProvider ? 'btnactive' : 'btninactive'}
//                   disabled={!isSpecificProvider}
//                 >
//                   Select Customers
//                 </button>
//               </div>

//               <div className="form-group" style={{ marginTop: 30 }}>
//                 <label className="h5-heaidng-invouice">Select Discount Type</label>
//                 <select
//                   value={discountType}
//                   onChange={handleDiscountTypeChange}
//                   className="select"
//                 >
//                   <option value="" disabled>Please Select Discount Type</option>
//                   <option value="fixed">Fixed</option>
//                   <option value="percentage">Percentage</option>
//                 </select>
//               </div>

//               <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
//                 <div className="form-group" style={{ width: '90%' }}>
//                   <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>Discount</label>
//                   <input
//                     type={discountType === 'percentage' ? 'number' : 'text'}
//                     placeholder={discountType === 'percentage' ? '00.00' : 'AED'}
//                     value={discount}
//                     onChange={(e) => setDiscount(e.target.value)}
//                     style={{ paddingBottom: '10px', borderBottom: '1px solid' }}
//                   />
//                   <span className="label-popup-seller" style={{ fontWeight: '700', position: 'absolute', right: '42.5%' }}>
//                     {discountType === 'percentage' ? '%' : 'AED'}
//                   </span>
//                 </div>

//                 <div className="form-group" style={{ width: '90%' }}>
//                   <label className="h5-heaidng-invouice" style={{paddingBottom:11}}>Promocode</label>
//                   <input
//                     type='text'
//                     placeholder='Promocode'
//                     value={promoCode}
//                     onChange={(e) => setPromoCode(e.target.value)}
//                     style={{ paddingBottom: '10px', borderBottom: '1px solid' }}
//                   />
//                 </div>
//               </div>
              
//               <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px' }}>
//                 <div className="form-group" style={{ width: '90%' }}>
//                   <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>Start Duration Date</label>
//                   <DatePicker onChange={onChange} value={value} />
//                 </div>

//                 <div className="form-group" style={{ width: '90%' }}>
//                   <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>End Duration Date</label>
//                   <DatePicker onChange={setEndValue} value={endValue} />
//                 </div>
//               </div>

//               <div className="form-group" style={{ paddingTop: '35px', paddingBottom: '35px' }}>
//                 <label className="h5-heaidng-invouice" style={{ paddingBottom: '10px' }}>Activate / Deactivate</label>
//                 <Switch
//                   onChange={() => setIsActive(!isActive)}
//                   checked={isActive}
//                   onColor="#747EEF"
//                   offColor="#bcbcbc"
//                   handleDiameter={17}
//                   uncheckedIcon={false}
//                   checkedIcon={false}
//                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
//                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                   height={20}
//                   width={36}
//                 />
//               </div>
//               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                 <button className="btn-set-promocode" onClick={handleAcceptClick}>
//                   Set Promo Code
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {showPopup && (
//         <Modal
//           visible={showPopup}
//           onCancel={() => setShowPopup(false)}
//           footer={null}
//         >
//           <div className="popup-container">
//             <div className="search-input">
//               <input
//                 type="text"
//                 placeholder="Search Customers"
//                 style={{ padding: 10 }}
//               />
//               <CiSearch size={20} />
//             </div>
//             <div className="customer-list">
//               {user.map((provider, index) => (
//                 <div key={index} className="customer-item">
//                   <img
//                     src={provider.image ? provider.image : DefulatImg}
//                     alt="customer"
//                     className="customer-image"
//                   />
//                   <div className="customer-details">
//                     <span className="customer-name">{provider.username}</span>
//                     <span className="customer-email">{provider.email}</span>
//                   </div>
//                   <Checkbox
//                     checked={selectedProviders.some(p => p._id === provider._id)}
//                     onChange={() => handleSelectProvider(provider)}
//                     inputProps={{ 'aria-label': 'controlled' }}
//                   />
//                 </div>
//               ))}
//             </div>
//             <button className="popup-button" onClick={() => setShowPopup(false)}>Confirm Selection</button>
//           </div>
//         </Modal>
//       )}
//       <AllGetPromoCode />
//     </div>
//   );
// };

// export default PromoCode;
