// import React, { useEffect, useState } from 'react';
// import './app.css';
// import ClipBord from '../../../assets/images/Clipboard.png'
// import LatestOrder from 'components/latestOrder/LatestOrder';
// import axios from 'axios';
// import { formatDate} from 'pages/utils/formdatedynamics';
// function Orders() {
//   const [loading,setLoading] = useState()
//   const [data,setData] =  useState([])
  
// const fetchData = async () => {
//   const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
//   const apiUrl = 'https://backend.washta.com/api/seller/orderbyStatus?status=completed';

//   try {
//     const response = await axios.get(apiUrl, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     console.log("responseactive order", response.data.data);
//     setData(response.data.data);
  
//   } catch (err) {
//     console.log(err.message);
//     setLoading(true);
//   } finally {
//     setLoading(false);
//   }
// };
// useEffect(()=>{
//   fetchData()
// },[])
//   return (
//     <div className="orders">
//       <div className='chart-colm-div-heading'> 
//           <img src={ClipBord} style={{width:'15%',objectFit:'contain'}}/>
//           <h2 className='main-heading2'>Total Orders</h2>
//           </div>
//       <div className="order-list">
//         {data?.map((data) => {

//           return(
//           <div key={data.id} style={{marginTop:20}}>
//             <LatestOrder Cost={data.cost} OrderDate={formatDate(data?.date)}  OrderId={data._id.slice(0,5)}status={data.status} MainName={data.name||"asdaa"} />
//             </div>
//             )
           
//         })}
//       </div>
//     </div>
//   );
// }

// export default Orders;
import React, { useEffect, useState } from 'react';
import './app.css';
import ClipBord from '../../../assets/images/Clipboard.png';
import LatestOrder from 'components/latestOrder/LatestOrder';
import axios from 'axios';
import { formatDate } from 'pages/utils/formdatedynamics';

function Orders() {
  const [loading, setLoading] = useState(false);
  const [ongoingOrders, setOngoingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  const fetchData = async (status, setter) => {
    const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
    const apiUrl = `https://backend.washta.com/api/seller/orderbyStatus?status=${status}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(`${status} orders`, response.data.data);
      setter(response.data.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    // Fetch ongoing orders
    fetchData('ongoing', setOngoingOrders);
    // Fetch completed orders
    fetchData('completed', setCompletedOrders);
  }, []);

  return (
    <div className="orders">
      <div className="chart-colm-div-heading">
        <img src={ClipBord} style={{ width: '15%', objectFit: 'contain' }} alt="Clipboard" />
        <h2 className="main-heading2">Total Orders</h2>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="order-section">
            <div className="order-list">
              {ongoingOrders?.map((data) => (
                <div key={data._id} style={{ marginTop: 20 }}>
                  <LatestOrder
                    Cost={data.cost}
                    OrderDate={formatDate(data?.date)}
                    OrderId={data._id.slice(0, 5)}
                    status={data.status}
                    MainName={data?.customerId?.username || "N/A"}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="order-section">
            <div className="order-list">
              {completedOrders?.map((data) => (
                <div key={data._id} style={{ marginTop: 20 }}>
                  <LatestOrder
                    Cost={data.cost}
                    OrderDate={formatDate(data?.date)}
                    OrderId={data._id.slice(0, 5)}
                    status={data.status}
                    MainName={data?.customerId?.username || "N/A"}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Orders;
