
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import '../invoice/invoice.css';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const NotificationCom = ({ Name, notificationMessages, Date, ImageNot, isLoading }) => {
  if (isLoading) {
    return (
      <div className="main-invoice-div" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '12px 12px', borderRadius: '10px' ,background:'#747eef9c'}}>
        <div className="first-colm-notification-com">
          <Skeleton variant="rectangular" width={48} height={48} style={{ marginRight: '10px', borderRadius: '8px',background:'#747eef9c'}} />
          <div className="text-notification-div">
            <Skeleton variant="text" width={100} height={20} style={{background:'#f1f2fd' }} />
            <Skeleton variant="text" width={200} height={15} style={{background:'#f1f2fd' }} />
          </div>
        </div>
        <div className="second-colm-notification-com">
          <Skeleton variant="text" width={150} height={20} />
        </div>
      </div>
    );
  }
  return (
    <div className="main-invoice-div" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '12px 12px', borderRadius: '10px' }}>
      <div className="first-colm-notification-com">
        <img
          style={{
            width: '48px',
            objectFit: 'cover',
            borderRadius: '21%',
            height: '48px',
            boxShadow: 'rgb(51, 51, 51) 0px 0px 3px 0px',
          }}
          src={ImageNot}
          alt="Notification"
        />
        <div className="text-notification-div">
          <h5 className="h5-heaidng-invouice">{Name}</h5>
          <p className="paragraph-notifcation">{notificationMessages}</p>
        </div>
      </div>
      <div className="second-colm-notification-com">
        <p className="paragph-heading">{formatDate(Date)} | At | {formatTimeInTimezone(Date)}</p>
      </div>
    </div>
  );
};

export default NotificationCom;
