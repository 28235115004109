import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = () => {
  const data = {
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        label: 'Overall Requests',
        data: [80, 20], // Example data
        backgroundColor: ['#1C2244', '#747EEF'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '80%',
  };

  return (
    <div style={styles.container}>
      <h2 className='main-heading2' style={{paddingLeft:0}}>Overall Requests</h2>
      <div className='destils-row-div'>
     <div style={{width:'50%'}}> <Doughnut style={{height:'120px',width:'120px'}} data={data} options={options} /></div>
      <div style={styles.legend}>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: '#1C2244' }}></span>
          <span>Completed</span>
        </div>
        <div style={styles.legendItem}>
          <span style={{ ...styles.legendColor, backgroundColor: '#747EEF' }}></span>
          <span>Pending</span>
        </div>
      </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    // position: 'relative',
    width: '100%',
    height: '100%',
    background: 'rgb(247, 247, 247)',
    borderRadius: '12px',
    padding: '20px',
    boxSizing: 'border-box',
    border: '0.5px solid #747eef'
  },

  legend: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '20px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  legendColor: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    marginRight: '10px',
    borderRadius: '50%',
  },
};

export default DonutChart;
