import AgentSidebar from 'agentfolder/sidebar/agentSideBar'
import ReviewSummary from 'pages/clientDashbaord/dashboard/ratingandReviews/ReviewSummary'
import React, { useEffect, useState } from 'react'
import LOGO from '../../assets/images/app.logo.jpeg';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './app.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
const AgentEditProfile = () => {
  const navigator = useNavigate()
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      
      const token = localStorage.getItem('agentToken');
      const apiUrl = 'https://backend.washta.com/api/agent/profile';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);
    const ratings = {
            1: 150,
            2: 70,
            3: 30,
            4: 15,
            5: 8,
          };
          const averageRating = 4.5;
      const totalReviews = 273;
      const recommendationPercentage = 88;
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
    <div className='div-Dashbaord'>
      <div className='row-div-admin-dashboard'>
    <h1 className='h1-all-heading'>Agent Profile </h1>
    
    
    </div>
    <div className="dashboard">
          <div className='styles-div'>
            <div className="chart-container">
              <div className='flex' style={{justifyContent:'space-between',}}>
                <div className='flex' style={{width:'50%',alignItems:'center'}}>
             
             <h2 className='main-heading2'>Franc Lee</h2>
                </div>
                <div className='flex' style={{width:'50%',alignItems:'center',justifyContent:'flex-end'}}>
                {/* <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick={()=>navigator('/agent/agent-edit-profile/edit-profile',{state:{data:data}})} textButton='Edit profile' height={35} width={'30%'} /> */}

                </div>
                
                </div>
               
                <div className='styles-div' style={{ width: '97%',paddingTop:30,display:'flex',justifyContent:'space-between',margin:'auto'}}>
               <div className='first-colm-form-editor'> 
                <h5 className='h5-heaidng-invouice'>Details</h5>
                <div  className='popup-inputfeild-application'>
                <p className='vat-number-api'>Username:</p>
                <p className='vat-number-api' style={{textAlign:'end',color:'black',fontWeight:400}}>{data?.username}</p>

              </div>
              <div  className='popup-inputfeild-application'>
                <p className='vat-number-api'>Role</p>
                <p className='vat-number-api' style={{textAlign:'end',color:'black',fontWeight:400}}>Agent</p>

              </div>
              <div  className='popup-inputfeild-application'>
                <p className='vat-number-api'>Joining Date</p>
                <p className='vat-number-api' style={{textAlign:'end',color:'black',fontWeight:400}}>{formatDate(data?.createdAt)} At {formatTimeInTimezone(data?.createdAt)}</p>
              </div>
                 </div>
               <div className='first-colm-form-editor' style={{display:'flex',justifyContent:'end'}}>
                <div style={{height:'50%'}}>
                <img src={LOGO} style={{width:'100%',objectFit:'cover',borderRadius:10, boxShadow: '0px 0px 3px 0px #333',height:'100%'}} />
                </div>
                 
                 </div>
          </div>
        
              </div>

            </div>
            <div className='styles-div-text'>
            <ReviewSummary text="Rating"  ratings={ratings}
        averageRating={averageRating}
        totalReviews={totalReviews}
        recommendationPercentage={recommendationPercentage} />
         </div>
          </div>
    </div>
    </div>
  )
}

export default AgentEditProfile