import Sidebar from 'pages/sideBar/SideBar';
import React, { useEffect, useState } from 'react';
import '../app.css';
import SellerShopCom from 'components/sellershopCom/SellerShopCom';
import { GoPlus } from "react-icons/go";
import PopupSellar from './popupsellar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Skeleton } from '@mui/material';

const SellarShop = () => {
    const [data, setData] = useState([]);
    const [userid, setUserid] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleEditClick = (item) => {
        setEditData(item); // Set the selected item's data for editing
        setIsPopupOpen(true); // Open the popup
      };
      const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setEditData(null); // Reset edit data when closing the popup
      };
    const handleDeleteShop = async (id) => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = `https://backend.washta.com/api/seller/shop/${id}`;

        try {
            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Shop deleted successfully');
            console.log('Deleted:', response.data);

            // Remove the deleted shop from the data array
            setData(prevData => prevData.filter(item => item._id !== id));
        } catch (err) {
            console.error('Error deleting shop:', err);
            toast.error('Failed to delete shop');
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userObject = JSON.parse(storedUser);
            const userId = userObject?.id;
            setUserid(userId);
        } else {
            console.log('No user data found in localStorage.');
        }
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = 'https://backend.washta.com/api/seller/shop';

        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("response", response.data.data);
            setData(response.data.data.reverse());
        } catch (err) {
            console.log(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleToggleChange = async (id, currentStatus) => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = `https://backend.washta.com/api/seller/openShop/${id}`;
        const newStatus = !currentStatus; // Toggle the status

        try {
            const response = await axios.patch(apiUrl, 
            { status: newStatus }, // Send the new status
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('responseresponseresponse',response.data,newStatus,id)
            // Update the state with the new status
            setData(prevData => prevData.map(item => 
                item._id === id ? { ...item, isActive: newStatus } : item
            ));

            toast.success(`Shop ${newStatus ? 'activated' : 'closed'} successfully`);
        } catch (err) {
            console.error('Error updating shop status:', err);
            toast.error('Failed to update shop status');
        }
    };

    const handleAccept = async (id) => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = `https://backend.washta.com/api/seller/shop/${id}`;

        try {
            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Successfully deleted');
            console.log('Deleted:', response.data);
            setData(data.filter(item => item._id !== id));
        } catch (err) {
            console.error('Error deleting:', err);
        }
    };

    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <Sidebar />
            </div>
            <div className='div-Dashbaord'>
                <div className='div-rewie-main'>
                    <h1 className='h1-all-heading'>Shop</h1>
                </div>
                <div className="dashboard">
                    <div className='styles-div-order-sellar'>
                        {isLoading ? (
                            Array.from(new Array(6)).map((_, index) => (
                              <div key={index} style={{ background:'#747eef9c', flex: '0.5 calc(30% - 1px)',  padding: '20px', border: '1px solid #e0e0e0', borderRadius: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                               <Skeleton variant="rectangular" width="100%" height={190} sx={{ borderRadius: 3}} />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton variant="text" width="60%" height={30} style={{background:'#f1f2fd'}} />
                <Skeleton variant="circular" width={30} height={30} style={{background:'#f1f2fd'}} />
            </Box>
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} style={{background:'#f1f2fd'}} />
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} style={{background:'#f1f2fd'}} />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton variant="rectangular" width="45%" height={30} sx={{ borderRadius: 1 }} style={{background:'#f1f2fd'}} />
                <Skeleton variant="rectangular" width="45%" height={30} sx={{ borderRadius: 1 }} style={{background:'#f1f2fd'}} />
            </Box>
                          </div>
                            ))
                        ) : (
                            data?.map((item) => (
                                <div key={item._id} style={{ flex: '0.2 calc(33% - 16px)' }}>
                                     <SellerShopCom
            editOnclick={() => handleEditClick(item)}
            onlcick={() => handleDeleteShop(item._id)}
            onClick={() => handleAccept(item._id)}
            img={item.coverImage}
            Price={item.cost}
            timing={item?.timing} // Pass the timing data here
            location={item.location?.text || "Dubai, Sharjah"}
            Heading={item.shopName}
            type={item.type}
            isActive={item.isOpen}
            onToggleChange={() => handleToggleChange(item._id, item.isOpen)}
        />
                                </div>
                            ))
                        )}
                        <div className='seller-shop-item' onClick={togglePopup}>
                            <div className='lightopcity-div'>
                                <GoPlus className='icons-seller' />
                                <p className='add-seller-shop'>Add Shop</p>
                            </div>
                        </div>
                    </div>
                </div>
                {isPopupOpen && (
        <div>
          <PopupSellar
            fetchData={fetchData}
            id={userid}
            Cross={togglePopup}
            editData={editData} // Pass editData if editing
          />
        </div>
      )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default SellarShop;
