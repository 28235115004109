import React from 'react';
import Appimg from '../../../assets/images/usertopcustomer.png'
import './app.css'
import TopCustomer from './TopCustomer';
import ImagePro from '../../../assets/images/app.logo.jpeg';

const TopCustomers = ({data}) => {

  return (
    <div className="latest-reviews">
     <div className='chart-colm-div-heading'> 
          <img src={Appimg} style={{width:'5%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Top Customer</h2>
          </div>
          <div className="responsive-container">
      {data?.map((item) => (
        <div key={item.id} className="responsive-item">
          <div className='tope-customer-com-main'>
<div className='second-row-customer' style={{ cursor: 'pointer' }} >
        <img src={item?.Avatar || ImagePro} className='image-pro-companies' style={{ boxShadow: '0px 0px 3px 0px #333', borderRadius: '21%' }} />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{item?.username}</h5>
          <p className='paragprh-customer-com' style={{width:'90%'}} >{item?.email} </p>
          {/* <p className='paragprh-customer-com'> <span style={{color:'#747EEF',fontSize:13,fontWeight:700}}> {item?.totalOrders} </span> Total Orders</p> */}
        </div>
      </div>
      <div className='second-row-customer' style={{ paddingTop:15, border: 'none', cursor: 'pointer' }} >
        <p className='paragprh-customer-com'>Total Orders</p>
        <p className='heading-sub-customer-com'>{item?.totalOrders}</p>
      </div>
      <div className='second-row-customer' style={{ paddingTop:5, border: 'none', cursor: 'pointer' }} >
        <p className='paragprh-customer-com'>Total Spent</p>
        <p className='heading-sub-customer-com'>{item?.totalSpents} <span style={{ fontSize: 9 }}>AED</span></p>
      </div>
      <div className='second-row-customer' style={{ paddingTop:5, border: 'none', cursor: 'pointer' }} >
        <p className='paragprh-customer-com'>Average Monthly Spents</p>
        <p className='heading-sub-customer-com'>{item?.averageMonthlySpents} <span style={{ fontSize: 9 }}>AED</span></p>
      </div>
         </div>
         </div>
      ))}
      </div>
    </div>
  );
};

export default TopCustomers;
