
import React from 'react';
import './LatestOrder.css';

const LatestOrder = ({ MainName, status, OrderId, OrderDate, Cost, orderdatespan }) => {
  // Determine the border color based on the status
  const getStatusBorderColor = () => {
    if (status === 'completed') return '#009B10';
    if (status === 'ongoing') return '#FF9900';
    return 'gray'; // Default border color
  };
  const getStatusColor = () => {
    if (status === 'completed') return '#009B10';
    if (status === 'ongoing') return '#FF9900';
    return 'gray'; // Default border color
  };

  return (
    <div
      className="latestOrder"
     
    >
      <div className="main-div-latest-order">
        <div>
          <h5 className="heading-h5-later">{MainName || 'Harry Makfee'}</h5>
        </div>
        {status && (
          <div className="div-btn-button"  style={{
            border: `1px solid ${getStatusBorderColor()}`,
          }}>
            <button className="btn-main-later" style={{
            color: `${getStatusColor()}`,
          }}>{status}</button>
          </div>
        )}
      </div>
      <div
        className="main-div-latest-order"
        style={{ marginTop: 15, marginBottom: 2 }}
      >
        <div className="div-order-inner">
          <p className="paragph-heading">Order ID:</p>
          <h5 className="heading-h5-later">{OrderId}</h5>
        </div>
        <div className="div-order-inner">
          <p className="paragph-heading">Order Date:</p>
          <h5 className="heading-h5-later">
            {OrderDate}{' '}
            {orderdatespan && <span style={{ fontSize: 9 }}>AED</span>}
          </h5>
        </div>
        <div className="div-order-inner">
          <p className="paragph-heading">Cost:</p>
          <h5 className="heading-h5-later">{Cost} AED </h5>
        </div>
      </div>
    </div>
  );
};

export default LatestOrder;
