
// import AdminSidebar from 'admin/sidebar/AdminSidebar';
// import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
// import React, { useEffect, useState } from 'react'
// import AgentRemoveCom from './AgentRemovecom';
// import PopupAgent from './popupAgent';
// import axios from 'axios';

// const AdminAgent = () => {
//   const [modalShow, setModalShow] = React.useState(false);
//    const [data,setData] = useState([])
//       useEffect(() => {
//         window.scrollTo(0, 0);
//         const fetchData = async () => {
//           const token = localStorage.getItem('accessTokenAdmin');
//           const apiUrl = 'https://backend.washta.com/api/admin/agent';
    
//           try {
//             const response = await axios.get(apiUrl, {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             });
//             setData(response.data.data.reverse());
//           } catch (err) {
//             console.log(err.message);
//           }
//         };
    
//         fetchData();
//       }, []);
//      const RemoveClick = async(id)=>{
//       const token = localStorage.getItem('accessTokenAdmin');
//       const deleteUrl = `https://backend.washta.com/api/admin/agent/${id}`; // Delete API endpoint
  
//       try {
//        const response = await axios.delete(deleteUrl, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setData((prevData) => prevData.filter(item => item.id !== id)); // Remove deleted item from state
//       } catch (err) {
//         console.error('Error deleting customer:', err.message);
//       }
//      }
//   return (
//     <div className='mainDiv-all-sideBar'>
//     <div className='div-sidebar'>
// <AdminSidebar/>
//     </div>
//     <div className='div-Dashbaord'>
//         <div className='width-settimg'>

//         <div class="receipt-row"> 
//         <h1 className='h1-all-heading'>Agents </h1>
//         <ButtonDahbaord  handleclick={() => setModalShow(true)} textButton="Add Agent" height={35} width={'16%'} background='purple' />
//         </div>
//         <PopupAgent
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//         <div className="chart-container">      
//         {data.map((item)=>(
//         <div key={item.id} style={{marginBottom:14}}>
//         <AgentRemoveCom click={ ()=> RemoveClick(item._id)} role={item.role} username={item.username}  time={item.time} date={item.date} img={item.img} name={item.name} />
//         </div>
//       ))}
      
      
//       </div>

//         </div>
   
    
//     </div>
//     </div>
//   )
// }

// export default AdminAgent
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect, useState } from 'react';
import AgentRemoveCom from './AgentRemovecom';
import PopupAgent from './popupAgent';
import axios from 'axios';
import NoData from '../../assets/images/nodataImg.png'
import './app.css'
const AdminAgent = () => {
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/agent';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  const RemoveClick = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const deleteUrl = `https://backend.washta.com/api/admin/agent/${id}`; // Delete API endpoint

    try {
      await axios.delete(deleteUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter(item => item._id !== id)); // Remove deleted item from state
    } catch (err) {
      console.error('Error deleting agent:', err.message);
    }
  };

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='width-settimg' style={{width:'100%'}}>
          <div className="receipt-row"> 
            <h1 className='h1-all-heading'>Agents</h1>
            <ButtonDahbaord 
              handleclick={() => setModalShow(true)} 
              textButton="Add Agent" 
              height={35} 
              width={'16%'} 
              background='purple' 
            />
          </div>

          <PopupAgent
            show={modalShow}
            onHide={() => setModalShow(false)}
          />

          {data.length === 0 ? (
            <div className="no-data-container">
              <img src={NoData} style={{width:'100%',height:'25em',objectFit:'contain'}} alt="No Data" className="no-data-image" />
      
            </div>
          ) : (
            <div className="chart-container">      
              {data.map((item) => (
                <div key={item.id} style={{ marginBottom: 14 }}>
                  <AgentRemoveCom 
                    click={() => RemoveClick(item._id)} 
                    role={item.role} 
                    username={item.username} 
                    time={item.time} 
                    date={item.createdAt} 
                    img={item.img} 
                    name={item.name} 
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminAgent;
