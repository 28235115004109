import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { saveAs } from 'file-saver';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import SatisSale from './StatisSale';
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import './app.css';

const DetailsSalePg = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const [data, setData] = useState([]);
    const [monthlySales, setMonthlySales] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessTokenAdmin');
            const apiUrl = `https://backend.washta.com/api/admin/SalesStats?shopId=${item?.shopId}`;

            try {
                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log('response.data?.data', response.data.data);
                setData(response?.data?.data || []);
                setMonthlySales(response?.data?.data?.graphData || []);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.clear();
                    navigate('/');
                }
            }
        };

        fetchData();
    }, [item?._id, navigate]);

    
    const exportToCSV = () => {
        const csvData = [
            [
                'Date',
                'Time',
                'Cus. Name',
                'Plate No.',
                'Vat 5%',
                'Status',
                'Ser. Provider',
                'Location',
                'Payment ID',
                'Cost',
                'Fee',
                'Total Price'
            ],
            ...data?.orders?.map(row => [
                formatDate(row.createdAt),
                formatTimeInTimezone(row.date),
                row.customerId?.username?.slice(0, 10) || 'N/A',
                row.vehicleId?.vehiclePlateNumber || 'N/A',
               row.cost ? (row.cost * 0.005).toFixed(2) : 'N/A',
                row.status,
                data?.shop?.shopName || 'N/A',
                row.location?.text || 'N/A',
                row.paymentId?.slice(0, 4) || 'N/A',
                row.cost || '0',
                row.fee || '0',
                `AED ${row.finalCost || '0'}`
            ])
        ];
    
        const csvContent = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
        // Save file
        saveAs(blob, 'orders-details.csv');
    };
    
    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <AdminSidebar />
            </div>
            <div className='div-Dashbaord'>
                <h1 className='h1-all-heading'>{item?.orders[0]?.shop?.shopName || "Shop Name"}</h1>
                <div className="dashboard">
                    <div className='styles-div addtionwidth-setting'>
                        <div className="dashboard-customer-details" style={{ width: '100%' }}>
                            <h2>Details</h2>
                            <div className='div-customer-details-main' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className='popup-inputfeild-application addtion-adding'>
                                    <p className='vat-number-api'>Service:</p>
                                    <input className='valt-number-input-feild' placeholder='text' value={item?.service || "Car Washing"} readOnly />
                                </div>
                                <div className='popup-inputfeild-application addtion-adding'>
                                    <p className='vat-number-api'>Location:</p>
                                    <input className='valt-number-input-feild' placeholder='text' value={item?.location?.text || "B1, ABC Mall Parking"} readOnly />
                                </div>
                            </div>
                        </div>

                        <div className="dashboard-customer-details" style={{ width: '100%' }}>
                            <h2>Statistics</h2>
                            <div className='div-customer-details-main' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <SatisSale salesData={monthlySales} />
                            </div>
                        </div>

                        <div className="dashboard-customer-details" style={{ width: '100%' }}>
                            <div className='div-row-type-sales'>
                                <h2>Details</h2>
                                <ButtonDahbaord handleclick={() => exportToCSV()} textButton="Export CSV" height={35} width={"13%"} background='purple' />
                            </div>

                            <div className='div-customer-details-main' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Time</TableCell>
                                                <TableCell>Customer Name</TableCell>
                                                <TableCell>Plate No.</TableCell>
                                                <TableCell>Vat 5%</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Service Provider</TableCell>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Invoice No.</TableCell>
                                                <TableCell>Net Price</TableCell>
                                                <TableCell>Platform Fee</TableCell>
                                               
                                                <TableCell>Total Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {data?.orders?.map((row, index) => (
    <TableRow key={index}>
        <TableCell>{formatDate(row.createdAt)}</TableCell>
        <TableCell>{formatTimeInTimezone(row.date)}</TableCell>
        <TableCell>{row.customerId?.username?.slice(0, 10) || 'N/A'}</TableCell>
        <TableCell>{row.vehicleId?.vehiclePlateNumber || 'N/A'}</TableCell>
        <TableCell>{row.cost ? (row.cost * 0.005).toFixed(2) : 'N/A'} AED</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{data?.shop?.shopName || 'N/A'}</TableCell>
        <TableCell>{row.location?.text || 'N/A'}</TableCell>
        <TableCell>{row.paymentId?.slice(0, 4) || 'N/A'}</TableCell>
        <TableCell>{row.cost || '0'}</TableCell>
        <TableCell>{row.fee ? (Math.round(row.fee * 10) / 10).toFixed(3) : '0'}</TableCell>
        <TableCell>{`AED ${row.finalCost ? (Math.round(row.finalCost * 10) / 10).toFixed(3) : '0'}`}</TableCell>
        </TableRow>
))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsSalePg;
