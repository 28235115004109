import React, { useEffect } from 'react';
import './Modal.css';
import { RxCross1 } from "react-icons/rx";
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

function PopupInvoice({ isOpen, onClose, data }) {
    useEffect(() => {
        // Handle clicks outside of the modal content
        const handleClickOutside = (event) => {
          if (event.target.classList.contains('modal-overlay')) {
            onClose();
          }
        };
    
        if (isOpen) {
          document.addEventListener('click', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isOpen, onClose]);
      const costPercentage = data?.cost ? (data?.cost * 0.05).toFixed(2) : 0;
      const finalCost = data?.cost && data?.fee ? (data?.cost - costPercentage - data?.fee).toFixed(2) : 'N/A';

      return (
        <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`}>
          <div className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
          <div className='row-modal-popup margin-bottom' >
          <h2 className='h2-heading-main'>Invoice Details</h2>
           <RxCross1 style={{cursor:'pointer'}} onClick={onClose}/>
          </div>
         
           
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api'>BillingStatus :</p>
              <input className='valt-number-input-feild' placeholder='text' value={data?.billingStatus || ''} readOnly />
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api' style={{width:"25%"}}>Status :</p>
            <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.status || ''} readOnly />
             
            </div>
            </div>
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api' style={{width:"40%"}}>Customer Email :</p>
              <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={data?.customerId?.username || ''} readOnly />
            </div>
            
            <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Cost :</p>
            <input className='valt-number-input-feild' placeholder='text' value={ data?.cost + ".00" +  " AED"  || ''} readOnly />
            </div>
            </div>

            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api' style={{width:"40%"}}>Customer Name :</p>
              <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={data?.customerId?.username || ''} readOnly />
            </div>
            
            <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api' style={{width:"25%"}}>Vat 5% :</p>
            <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={costPercentage + " AED"  || 'none'} readOnly />
            </div>
            </div>
            
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
            <p className='vat-number-api' style={{width:"25%"}}>Location :</p>
            <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.location?.text || ''} readOnly />
             
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api' style={{width:"40%"}}>Washta Fee 15% :</p>
            <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={ data?.fee + " AED"  || 'none'} readOnly />
            </div>
            </div>
            <div className='popup-pending-appliction'>
           
           <div className='popup-inputfeild-application addtionwidth' >
           <p className='vat-number-api' style={{width:"25%"}}>Date :</p>
            <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={formatDate( data?.date )  +  " At "  +  formatTimeInTimezone( data?.date) || ''} readOnly />
           </div>
           <div className='popup-inputfeild-application addtionwidth'>
             <p className='vat-number-api' style={{width:"40%"}}>Total :</p>
             <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={ finalCost + " AED"  || 'none'} readOnly />
           </div>
           </div>
            {/* Add more details as needed */}
          </div>
        </div>
      );
    }

export default PopupInvoice;
