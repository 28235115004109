// import React, { useState, useRef, useEffect } from 'react';
// import '../Become.css';
// import { Button } from '../../../components/Button/index';
// import axios from 'axios';
// import { Spinner } from 'react-bootstrap';

// const ForgotOtpScreen = ({ email }) => {
//   const [otp, setOTP] = useState(['', '', '', '']);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [token ,setToken ] = useState('')
//   const [isOtpVerified, setIsOtpVerified] = useState(false); // State to track if OTP is verified
//   const [newPassword, setNewPassword] = useState(''); // State for new password
//   const otpBoxes = Array.from({ length: 4 }, (_, index) => index);
//   const inputRefs = useRef([]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handleChange = (index, value) => {
//     if (/^\d*$/.test(value) && value.length <= 1) {
//       const newOTP = [...otp];
//       newOTP[index] = value;
//       setOTP(newOTP);

//       if (value.length === 1 && index < 3) {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   const handleSubmitOtp = async () => {
//     setLoading(true);
//     const otpValue = otp.join('');
//     const payload = {
//       code: otpValue,
//       role: "seller",
//       email: email
//     };
//     console.log('payload ', payload);
//     try {
//       const response = await axios.post('https://backend.washta.com/api/otp/verifyCode', payload, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       if (response.data.status && response.data.code === 200) {
//         console.log('Success:', response.data.data.token);
//         setToken(response.data.data.token)
//         setIsOtpVerified(true); // OTP verified, show password input
//       } else {
//         setError('OTP verification failed');
//       }
//     } catch (error) {
//       console.error('Error during OTP verification:', error.response?.data);
//       setError(error.response?.data?.error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleChangePassword = async () => {
//     if (!newPassword) {
//       setError('Password cannot be empty');
//       return;
//     }

//     setLoading(true);
//     try {
//       const payload = {
//         email: email,
//         newPassword: newPassword
//       };
//       const response = await axios.post('https://backend.washta.com/api/changePassword', payload, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       if (response.data.status && response.data.code === 200) {
//         console.log('Password changed successfully:', response.data);
//         // Optionally, navigate to another page or show success message
//       } else {
//         setError('Failed to change password');
//       }
//     } catch (error) {
//       console.error('Error during password change:', error.response?.data);
//       setError(error.response?.data?.error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="otp-container fade-in">
//       <h1 className='h1-heaing'>Enter your verification code</h1>
//       <p className='paragraph-otp'>
//         Enter 4 digit verification code sent to your registered Email Verification.
//       </p>
//       <div className="otp-input-container">
//         {!isOtpVerified ? (
//           otpBoxes.map(index => (
//             <input
//               key={index}
//               ref={el => inputRefs.current[index] = el}
//               className="otp-input"
//               type="text"
//               value={otp[index]}
//               maxLength={1}
//               onChange={(e) => handleChange(index, e.target.value)}
//             />
//           ))
//         ) : (
//           <div className="new-password-container">
//             <input
//               type="password"
//               className="password-input"
//               placeholder="Enter new password"
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//             />
//           </div>
//         )}
//       </div>
      
//       {error && <p className='error-paragrhp'>{error}</p>}

//       <div className="actions-container">
//         {!isOtpVerified ? (
//           <Button
//             onClick={handleSubmitOtp}
//             shape="round"
//             className="min-w-[100%] sm:px-1"
//             style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}
//           >
//             {loading ? <Spinner animation="border" style={{ color: '#747EEF', width: '1.5em', height: '1.5em' }} size="sm" /> : 'Submit'}
//           </Button>
//         ) : (
//           <Button
//             onClick={handleChangePassword}
//             shape="round"
//             className="min-w-[100%] sm:px-1"
//             style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}
//           >
//             {loading ? <Spinner animation="border" style={{ color: '#747EEF', width: '1.5em', height: '1.5em' }} size="sm" /> : 'Change Password'}
//           </Button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ForgotOtpScreen;
import React, { useState, useRef, useEffect } from 'react';
import '../Become.css';
import { Button } from '../../../components/Button/index';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import InputFeild from '../../../components/Input/Input'
import { useLocation, useNavigate } from 'react-router-dom';
const ForgotOtpScreen = ({ email,item }) => {
  const [otp, setOTP] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false); // State to track if OTP is verified
  const [newPassword, setNewPassword] = useState(''); // State for new password
  const otpBoxes = Array.from({ length: 4 }, (_, index) => index);
  const inputRefs = useRef([]);

  const handleRedirect = () => {
    if (item === 'admin') {
      navigate('/super-admin-login');
    } else if (item === 'seller') {
      navigate('/sign-in');
    } else {
      console.log('Invalid role');
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      if (value.length === 1 && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleSubmitOtp = async () => {
    setLoading(true);
    const otpValue = otp.join('');
    const payload = {
      code: otpValue,
      role: item,
      email: email
    };
    console.log('payload ', payload);
    try {
      const response = await axios.post('https://backend.washta.com/api/otp/verifyCode', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status && response.data.code === 200) {
        console.log('Success:', response.data.data.token);
        setError('')
        setToken(response.data.data.token);
        setIsOtpVerified(true); // OTP verified, show password input
      } else {
        setError('OTP verification failed');
      }
    } catch (error) {
      console.error('Error during OTP verification:', error.response?.data);
      setError(error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword) {
      setError('Password cannot be empty');
      return;
    }

    setLoading(true);
    try {
      const payload = {
        token:token,
        role: item,
        newPassword: newPassword
      };
      const response = await axios.post('https://backend.washta.com/api/otp/setPassword', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status && response.data.code === 200) {
        console.log('Password changed successfully:', response.data);
        handleRedirect()
        setError('')
        // Optionally, navigate to another page or show success message
      } else {
        setError('Failed to change password');
      }
    } catch (error) {
      console.error('Error during password change:', error.response?.data);
      setError(error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="otp-container fade-in">
      <h1 className='h1-heaing'>{isOtpVerified ? 'Set Your New Password' : 'Enter your verification code'}</h1>
      <p className='paragraph-otp'>
        {isOtpVerified 
          ? 'Enter your new password below to complete the process.' 
          : 'Enter 4 digit verification code sent to your registered Email Verification.'}
      </p>
      <div className="otp-input-container">
        {!isOtpVerified ? (
          otpBoxes.map(index => (
            <input
              key={index}
              ref={el => inputRefs.current[index] = el}
              className="otp-input"
              type="text"
              value={otp[index]}
              maxLength={1}
              onChange={(e) => handleChange(index, e.target.value)}
            />
          ))
        ) : (
          <div className='gapmargin' style={{width:'100%',textAlign:'left'}}>
           <InputFeild
              label="New Password"
              placeholder="Enter new password"
              type="password"
              value={newPassword}
              onChange={setNewPassword}
              paddingBottom="10px"
            />
            {/* <input
              type="password"
              className="password-input"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            /> */}
          </div>
        )}
      </div>
      
      {error && <p className='error-paragrhp'>{error}</p>}

      <div className="actions-container">
        {!isOtpVerified ? (
          <Button
            onClick={handleSubmitOtp}
            shape="round"
            className="min-w-[100%] sm:px-1"
            style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}
          >
            {loading ? <Spinner animation="border" style={{ color: '#747EEF', width: '1.5em', height: '1.5em' }} size="sm" /> : 'Submit'}
          </Button>
        ) : (
          <Button
            onClick={handleChangePassword}
            shape="round"
            className="min-w-[100%] sm:px-1"
            style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}
          >
            {loading ? <Spinner animation="border" style={{ color: '#747EEF', width: '1.5em', height: '1.5em' }} size="sm" /> : 'Change Password'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ForgotOtpScreen;
