import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react'
import './app.css'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const Sendnotifications = () => {
    const [recipient, setRecipient] = useState('');
    const [message, setMessage] = useState('');
    const [title,setTitle] = useState('')
    const [loading, setLoading] = useState(false); // Loading state for spinner
    const handleSendNotification = () => {
        SubmitNotification();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const SubmitNotification = async () => {
      setLoading(true)
        try {
            const payload = {
                title: title,
                body: message || "This notification is just for testing"
            };

            const token = localStorage.getItem('accessTokenAdmin');
            let apiUrl = `https://backend.washta.com/api/admin/sendNotification?sendTo=${recipient}`;
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            toast.success('Notification Sent Successfully');
            setRecipient('');
            setMessage('');
            setTitle('')
        } catch (error) {
            console.error('Error sending notification:', error);
        }finally {
          setLoading(false); // Set loading to false once API request is done
      }
    };

    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <AdminSidebar />
            </div>
            <div className='div-Dashbaord'>
                <h1 className='h1-all-heading'>Send Notification</h1>
                <div className="dashboard">
                    <div className='styles-div'>
                        <div className="chart-container">
                            <div>
                                <div className="form-group">
                                    <label className="h5-heaidng-invouice">Send To:</label>
                                    <select
                                        value={recipient}
                                        onChange={(e) => setRecipient(e.target.value)}
                                        className="select"
                                    >
                                        <option value="" disabled>Select Recipients</option>
                                        <option value="both">Both Buyers and Sellers</option>
                                        <option value="buyers">Buyers</option>
                                        <option value="sellers">Sellers</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                <label className="h5-heaidng-invouice">Title:</label>

                                  <input  value={title} onChange={(e) => setTitle(e.target.value)} type='text' placeholder='Title' style={{borderBottom:'1px solid black',marginBottom:30}}/>
                                    <label className="h5-heaidng-invouice">Enter Message:</label>
                                    <textarea
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows="10"
                                        className="textarea"
                                    ></textarea>
                                </div>

                                <div className='btn-job-histoiuyr' style={{ width: '100%' }}>
                                <ButtonDahbaord
                                        handleclick={handleSendNotification}
                                        textButton={loading ? (
                                            <CircularProgress size={20} style={{ color: 'white' }} /> // Show spinner while loading
                                        ) : "Send Notification"}
                                        height={35}
                                        width={'30%'}
                                        background='purple'
                                        disabled={loading} // Disable button when loading
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default Sendnotifications;
