import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa'; // Importing a trash icon from react-icons

const ServicesFeesApplying = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/serviceFee';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
        console.log('response.data.data.',response.data.data)
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/serviceFee/${id}`;

    try {
      await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the deleted promo code from the state
      setData((prevData) => prevData.filter((promo) => promo._id !== id));
    } catch (err) {
      console.log('Error deleting promo code:', err.message);
    }
  };

  return (
    <div>
      {data?.map((promo) => (
        <div key={promo._id} className='tope-customer-com-main' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div className='second-row-customer' style={{ cursor: 'pointer', width: '20%' }}>
            <div className='top-custorm-width'>
              <h5 className='paragph-heading'>Washta Fees</h5>
              <p className='h5-class-top-class'>
          {promo?.feeType === 'percentage'
            ? `${promo?.WashtaFees}%`
            : `${promo?.WashtaFees} AED`}
        </p>            </div>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Fee Type</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{promo?.feeType}</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Number Of Shop</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{promo?.applyAt.length}</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>Apply As</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>{promo?.applyAs}</p>
          </div>
          <div className='div-colm-order' style={{ width: '20%' }}>
            <p className='paragph-heading' style={{ fontSize: 11 }}>IsAmount Taxable</p>
            <p className='h5-class-top-class' style={{ fontWeight: 400 }}>        {promo?.isAmountTaxable ? 'Yes' : 'No'}            </p>
          </div>
          <div className='div-colm-order' style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            
            <FaTrash
              onClick={() => handleDelete(promo._id)}
              style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesFeesApplying;
