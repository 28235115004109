import Footer from 'components/Footer'
import Header from 'pages/Home1/Header'
import React, { useEffect, useState } from 'react'
import ImgSingin from '../../assets/images/agent.png'
import Overly from '../../assets/images/overly.png'
import InputFeild from 'components/Input/Input'
import { Button } from 'components'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
const AgentLogin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  
  const handleSignin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://backend.washta.com/api/agent/login',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const { status, code, message, data } = response.data;
  
      if (status === true && code === 200) {
        toast.success('Sign in successful!');
        console.log('Response Data:', data); 
        localStorage.setItem('Agentuser', JSON.stringify(data.user));
      localStorage.setItem('agentToken', data.accessToken);
      setTimeout(()=>{
        navigate('/agent-dashboard');
      },[2000])  
    
        
      } else if (status === false && code === 200) {
        toast.warning(message);
        navigate('/not-verified'); // Navigate to not-verified page
      } else {
        toast.error('Sign in failed. Please try again.');
      }
    } catch (error) {
      toast.error('Sign in failed. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
  };
}
  
  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='colm-first'> <img src={ImgSingin} alt="Logo"  /> </div>
          <div className='colm-first add'>
              <div className='innerDiv-form'>
                <h1 className='h1-heaing'>Welcome back! </h1>
                <p className='paragraph-otp'> Log into your account </p>
                <div className='gapmargin'> <InputFeild type='email' label="Username" placeholder="Enter your username" onChange={(value) => handleChange('identifier', value)} /></div>
                <div className='gapmargin'> <InputFeild type='password' label="Password" placeholder="Enter your Password" onChange={(value) => handleChange('password', value)} /></div>
                {/* <p className='paragraph-otp' style={{marginTop:'2em'}}> Forgot Password? </p> */}
                <Button onClick={handleSignin}  shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px',fontFamily:"'General Sans', sans-serif" }}>
                {loading ? <Spinner animation="border" style={{color:'#747EEF',width:'1.5em',height:'1.5em'}}  size="sm" /> : 'Sign in'}
                </Button>
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <ToastContainer/>
      <Footer />
    </>
  )
}

export default AgentLogin;
