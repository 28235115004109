import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './app.css';
import Appimg from '../../../assets/images/latestreviews.png'
import { Rating, Box, Skeleton, } from '@mui/material';
import {  MdDelete } from "react-icons/md";
import Orders from './LatestOrder';
import TotalOrdersChart from './TotalOrderChart';
import DashbaordImgtext from 'components/latestOrder/DashbaordImgtext';
import Sidebar from 'pages/sideBar/SideBar';
import LatestReviews from './LatestReviews';
import img1 from '../../../assets/images/LatestReView.png'
import img2 from '../../../assets/images/acceptorder.png'
import img3 from '../../../assets/images/cancel.png'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = 'https://backend.washta.com/api/seller/shopReview';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.data.reverse());
    } catch (err) {
      console.error('Error reponse data:', err);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (reviewId, ) => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = `https://backend.washta.com/api/seller/deleteReview/${reviewId}`
     

    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Delete successful', response.data);
      fetchData();
    } catch (err) {
      console.error('Error deleting:', err.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const stats = useSelector(state => state.stats);
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<Sidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Dashboard</h1>
    <div className="dashboard">
     
      <div className='styles-div'>

<TotalOrdersChart/>
      
      {/* <Line data={data} /> */}
      <div className="stats" style={{gap:20}}>
        <DashbaordImgtext heading='Total Orders' value={stats?.totalNumberOfOrders || stats?.totalAcceptedOrders}  img={img1}/>
        <DashbaordImgtext heading='Accepted orders' value={stats?.totalCompletedOrders || stats?.totalAcceptedOrders} img={img3}/>
        <DashbaordImgtext heading='Cancelled orders' value={stats?.totalCancelledOrders || stats?.totalCancelledOrders} img={img2}/>
      </div>
      <div className="stats" style={{gap:20,justifyContent:'start'}}>
      {stats?.totalRevenue !== undefined && (
  <DashbaordImgtext heading='Total Revenue' value={stats?.totalRevenue} img={img3} />
)}
        <DashbaordImgtext heading='Yearly Revenue' value='3313' img={img3}/>

      </div>
      {/* <LatestReviews/> */}
      <div className='invoice-border'>
      <div className='chart-colm-div-heading'> 
          <img src={Appimg} style={{width:'5%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Latest Reviews</h2>
          </div>
      {loading ? (
                <Box sx={{ width: '100%', marginTop: 1 }}>
                  {[1, 2, 3].map((index) => (
                    <Box key={index} sx={{ marginBottom: 2, background: '#747eef9c', borderRadius: 4, paddingTop: 1, paddingBottom: 1 }}>
                      <Skeleton variant="text" width="20%" height={20} sx={{ marginLeft: 2, background: '#f1f2fd', borderRadius: 1 }} />
                      <Skeleton variant="rectangular" width="96%" height={40} sx={{ marginBottom: 1, marginLeft: 2, background: '#f1f2fd', borderRadius: 1 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-6px', paddingLeft: 2, paddingRight: 2 }}>
                        <Skeleton variant="text" width="30%" height={20} style={{ background: '#f1f2fd' }} />
                        <Skeleton variant="rectangular" width={80} height={13} style={{ marginTop: 1, background: '#f1f2fd', borderRadius: 2 }} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                data.map((review) => (
                  <div key={review._id} className="review-box">
                    <div className='div-rewie-main'>
                      <h5 className='heading-h5-later'>{review.customerId?.username || 'Anonymous'}</h5>
                      <Rating name="half-rating-read" value={review.rating || 0} precision={0.5} sx={{ fontSize: 20, color: '#F39D00' }} readOnly />
                    </div>
                    
             <p className='paragph-heading addtion-adding'>{review.comment?.text || 'No comment available'}</p>

                    <div className='div-rewie-main'>
                      <p className='paragph-heading' style={{color:'#747EEF'}}>{formatDate(review.date) + ' At ' + formatTimeInTimezone(review.date)} </p>
                     
                            <MdDelete style={{color:'#747EEF',cursor:'pointer',fontSize:23,marginTop:'-10px'}} onClick={() => handleDelete(review._id, null, true)} />
                         
                     
                     
                    </div>
                    
                  </div>
                ))
              )}
      </div>
      </div>
      <div className='styles-div-text' >
        <Orders/>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Dashboard;
