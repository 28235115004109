import React, { useEffect, useState } from 'react';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { CiSearch } from "react-icons/ci";
import TopCustomers from 'admin/components/jobhistory/topcustomers';
import './Customer.css';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import TopCustomer from 'admin/components/dashboardcom/TopCustomer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import { Skeleton } from '@mui/material';

function Customers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topCustomerSideBardata,settopCustomerSideBardata] = useState([])
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const navigator = useNavigate();
  const storedData = localStorage.getItem('topcustomerData');
 
  useEffect(() => {
    window.scrollTo(0, 0);
    if (storedData) {
      try {
          const topCustomerData = JSON.parse(storedData);
          settopCustomerSideBardata(topCustomerData)
      } catch (error) {
          console.error("Invalid JSON format in localStorage:", error);
      }
  } else {
      console.log("No data found in localStorage.");
  }
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/JobHistory';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  console.log('v',topCustomerSideBardata)

  const filteredData = data.filter(item => {
    const usernameMatch = item.customerId?.username.toLowerCase().includes(searchQuery.toLowerCase());
    const dateMatch = formatDate(item?.date).includes(searchQuery);
    return usernameMatch || dateMatch;
  });

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Customers</h1>
        <div className="dashboard">
          <div className='styles-div'>
            <div className="chart-container">
              <div className='flex'>
                <div className='input-feild-dic-icon'>
                  <CiSearch className='icon-serach-box-input' />
                  <input
                    placeholder='Search by customer name / date'
                    className='input-feild-class-job-history'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                  />
                </div>
                <div className='btn-job-histoiuyr'>
                  <ButtonDahbaord
                    handleclick={() => navigator('/super-admin-dashboard/customers/all-customers', { state: { item: filteredData } })}
                    textButton="View all"
                    height={35}
                    width={'50%'}
                    background='purple'
                  />
                </div>
              </div>

              <div className="responsive-container">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div key={index} className="responsive-item">
                      <div className="customer-card-skeleton">
                        <div className="avatar-skeleton" style={{display:'flex',gap:'13px',alignItems:'center'}}>
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'30%'} height={60} />
                          <div style={{width:'70%'}}>
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={20} />
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={15} />
                          </div>
                        </div>
                        <div className="info-skeleton">
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={20} />
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={15} />
                          
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  filteredData.map((item) => (
                    <div key={item.id} className="responsive-item">
                      <TopCustomer
                      Item={item}
                      onDeleteInoive={item._id}
                        ID={item.customerId?._id}
                        paymentId={item?.paymentId?.slice(0, 4)}
                        Avatar={item.customerId?.avatar}
                        paymnet={item.cost}
                        textpopupBtn="Share details"
                        status={item.status}
                        orderTime={formatTimeInTimezone(item?.date)}
                        orderDate={formatDate(item?.date)}
                        orderId={" WS " + "- " + item._id.slice(0, 5)}
                        carPlate={item.vehicleId?.vehiclePlateNumber}
                        carType={item.vehicleId?.vehicleType   }
                        vist={formatDate(item?.date) + " At " + formatTimeInTimezone(item?.date)}
                        car={item.vehicleId?.vehicleName  }
                        buyerName={item.customerId?.username}
                        show={true}
                        Completed={item.status}
                        name={item.customerId?.username}
                        spent={item?.cost}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className='styles-div-text'>
            <TopCustomers Data={topCustomerSideBardata} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
