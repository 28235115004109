import React from 'react';
import ClipBord from '../../../assets/images/usertopcustomer.png'
import Imgaaa from '../../../assets/images/app.logo.jpeg';
import './Recepit.css'
function TopCustomers({Data}) {

  return (
    <div className="orders">
      <div className='chart-colm-div-heading'> 
          <img src={ClipBord} style={{width:'13%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Top Customers</h2>
          </div>
      <div className="order-list">
        {Data?.map((data) => {
          console.log(data)
          return(
          <div key={data._id} >
            <div className='second-row-customer customer-pending' style={{gap:'20px'}} >
             <div style={{width:'60%',display:'flex',gap:'5px'}}>
             <img src={data?.avatar || Imgaaa} className='topcustomerdata'/>
              <div className='top-custorm-width'>
                <h5 className='h5-class-top-class'>{data.username}</h5>
                <p className='paragprh-customer-com'>{data.totalOrders} Vist </p>
              </div>
             </div>
             
              <div className='top-custorm-width' style={{width:'30%', textAlign:'start'}}>
                <p className='paragprh-customer-com'>Total Spent</p>
                <h5 className='h5-class-top-class'>{data.totalSpents}<span style={{fontSize:9}}>AED</span></h5>
              </div>
            </div>
            </div>
            )
           
        })}
      </div>
    </div>
  );
}

export default TopCustomers;
