import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react';
import { TimePicker, Modal } from 'antd';
import Switch from 'react-switch';
import moment from 'moment';
import './ServiceTiming.css';
import { CiSearch } from "react-icons/ci";
import { Checkbox, FormControlLabel } from '@mui/material';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import axios from 'axios';
import DefulatImg from '../../assets/images/app.logo.jpeg';
import { ToastContainer, toast } from 'react-toastify';
import GetAllShop from './GetAllShop';

const ServiceTiming = () => {
  const [isSpecificProvider, setIsSpecificProvider] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [timeSettings, setTimeSettings] = useState({
    Monday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Tuesday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Wednesday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Thursday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Friday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Saturday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Sunday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
  });
  const user = JSON.parse(localStorage.getItem('superAdmin'));
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
     
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/shop';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.error('Error fetching data:', err.message);
      }
    };

    window.scrollTo(0, 0); // Scroll to the top on load
    fetchData();
  }, []);
  const handleToggle = (day) => {
    setTimeSettings({
      ...timeSettings,
      [day]: { ...timeSettings[day], active: !timeSettings[day].active }
    });
  };

  const handleTimeChange = (day, type, value) => {
    setTimeSettings({
      ...timeSettings,
      [day]: { ...timeSettings[day], [type]: value }
    });
  };
  const filteredData = data.filter((provider) =>
    provider.shopName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSelectProvider = (provider) => {
  
    setSelectedProviders((prev) => {
      const isSelected = prev.some((item) => item.id === provider._id); // Check if already selected
      if (isSelected) {
        // Remove the object if it's already selected
        return prev.filter((item) => item.id !== provider._id);
      } else {
        // Add the object if it's not already selected
        return [...prev, { id: provider._id, shopName: provider.shopName }];
      }
    });
  };

  const handleSwitchChange = () => {
    setIsSpecificProvider(!isSpecificProvider);
    if (!isSpecificProvider) {
      setSelectedProviders([]);
    }
  };
  const handleSelectAll = () => {
    if (selectedProviders.length === data.length) {
      setSelectedProviders([]); // Deselect all if all are selected
    } else {
      setSelectedProviders(
        data.map((provider) => ({
          id: provider._id,
          shopName: provider.shopName,
        }))
      ); // Select all with id and shopName
    }
  };

  useEffect(() => {
    console.log("Selected Providers: ", selectedProviders.map(p => ({ id: p.id, name: p.name })));
    console.log("Time Settings: ", timeSettings);
  }, [selectedProviders, timeSettings]);
  const handleAcceptClick = async() => {
    if (selectedProviders?.length === 0) {
      toast.error('Please select providers or apply to all.');
      return;
    }
    const payload = {
      user_id: user?.id, // Assuming the user object has an _id field
      shopId: selectedProviders.map(provider => provider.id),
      timing: Object.keys(timeSettings).reduce((acc, day) => {
        // Include all days (active or inactive)
        acc[day.toLowerCase()] = {
          open: timeSettings[day]?.active || false,  // If the day is active, set open to true, else false
          from: timeSettings[day]?.start ? timeSettings[day].start.toISOString() : null,  // If start exists, set ISO string, else null
          to: timeSettings[day]?.end ? timeSettings[day].end.toISOString() : null,  // If end exists, set ISO string, else null
        };
        return acc;
      }, {}),
    };
    const token = localStorage.getItem('accessTokenAdmin');
    try {
      const response = await axios.patch('https://backend.washta.com/api/admin/shop', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(payload, 'response', response.data);
      toast.success('Service fee updated successfully!');
      // window.location.reload();
    } catch (error) {
      console.error('API Error:', error.response ? error.response.data : error.message);
      toast.error('Failed to update service fee. Please try again.');
    }
  };
  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='width-settimg addtionwidth-setting'>
          <div className='receipt-row' style={{ alignItems: 'center' }}>
            <h1 className='h1-all-heading'>Service Timing </h1>
            <div className='receipt-row' style={{ gap: 20 }}>
              <p className='h5-class-top-class' style={{ fontSize: 16, fontWeight: 500 }}>Specific Service Provider</p>
              <Switch
                onChange={handleSwitchChange}
                checked={isSpecificProvider}
                onColor="#747EEF"
                offColor="#bcbcbc"
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={36}
              />
            </div>
          </div>
          <div className="dashboard">
            <div className="dashboard-customer-details" style={{ width: '100%' }}>
              <div className="receipt-row" style={{ paddingTop: 10 }}>
                <div style={{width:'75%'}}>
                  <p className='paragprh-customer-com'>Manage operating schedule for all service providers or specific one</p>
                  <div style={{ marginTop: 15 }}>
  <span>Specific Service Providers:</span>
  <span >
    {selectedProviders.map((p, index) => (
      <span className="span-seleceted-servicetiming" key={index} style={{ marginRight: '10px' }}>
      {p.shopName}
    </span>
    ))}
  </span>
</div>

                </div>
                <button
                  onClick={() => isSpecificProvider && setShowPopup(true)}
                  className={isSpecificProvider ? 'btnactive' : 'btninactive'}
                  disabled={!isSpecificProvider}
                >
                  Select Service Providers
                </button>
              </div>
              <div className="timing-table">
                {Object.keys(timeSettings).map((day) => (
                  <div key={day} className="day-row">
                    <span >{day}</span>
                    <div className='padding-right-property'>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        value={timeSettings[day].start}
                        onChange={(value) => handleTimeChange(day, 'start', value)}
                        disabled={!timeSettings[day].active}
                      />
                      -
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        value={timeSettings[day].end}
                        onChange={(value) => handleTimeChange(day, 'end', value)}
                        disabled={!timeSettings[day].active}
                      />
                    </div>
                    <Switch
                      checked={timeSettings[day].active}
                      onChange={() => handleToggle(day)}
                      onColor="#747EEF"
                      offColor="#bcbcbc"
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      Checkbox={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={36}
                    />
                  </div>
                ))}
              </div>
              <div style={{ textAlign: 'end', marginTop: 20 }}>
                <ButtonDahbaord
                  handleclick={handleAcceptClick}
                  textButton="Save"
                  height={32}
                  width={"30%"}
                  background='purple'
                />
              </div>
            </div>
           
            <Modal
            title="Select Service Provider(s)"
            open={showPopup}
            onCancel={() => setShowPopup(false)}
            footer={null}
            centered
          >
            <div className='input-feild-dic-icon' style={{width:'100%'}}>
              <CiSearch className='icons-search-size' />
              <input
                type="text"
                placeholder='Search by service provider'
                className='input-feild-class-job-history'
                value={searchQuery} // Bind the search state
                onChange={(e) => setSearchQuery(e.target.value)} 
              />
            </div>

            <div style={{ height: '22em', overflow: 'scroll' }}>
            <FormControlLabel
  style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row-reverse' }}
  label="SSelect All"
  control={<Checkbox checked={selectedProviders.length === data.length} onChange={handleSelectAll} />}
/>
              <div className="boxCustomerAll">
                {filteredData?.map((provider) => (
                    <div
    className='tope-customer-com-main'
    key={provider._id}
    style={{ display: 'flex', justifyContent: 'space-between', padding: '11px 9px' ,margin:'10px 0px 15px 0px'}}
  >
    <div className='second-row-customer'>
      <img
        src={provider.coverImage || DefulatImg}
        alt="Provider"
        className="image-pro-companies"
      />
      <div className='top-custorm-width'>
        <h5 className='h5-class-top-class'>{provider.shopName}</h5>
        <p className='paragraph-text-p'>{provider.location?.text}</p>
      </div>
    </div>
    <Checkbox
  checked={selectedProviders.some((item) => item.id === provider._id)} // Correctly match the `id`
  onChange={() => handleSelectProvider(provider)} // Handle selection
/>
  </div>
                ))}
              </div>
            </div>
            <div className='container-footer'>
              <ButtonDahbaord
                handleclick={() => setShowPopup(false)}
                textButton="Close"
                height={30}
                width={"100%"}
                background='purple'
              />
            </div>
          </Modal>
          </div>
          <div className='dashboard-customer-details' style={{ width: '100%' }}>
            <label className="label-popup-seller" style={{ paddingTop: 11, fontSize: '18px', paddingRight: '10px' }}>Get all Updated Shop</label>
            <GetAllShop  data={data} />
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default ServiceTiming;
