import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/image 1.png';
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { PiChatsCircle } from "react-icons/pi";
import { BsPersonVcardFill } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import '../../admin/components/dashboardcom/app.css'
import LogoutPopup from '../../components/logoutpopup/LogoutPopup';

function AgentSidebar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigation = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const HandleLogout = () => {
    localStorage.removeItem("Agentuser");
    localStorage.removeItem("agentToken");
    localStorage.clear();
    navigation('/');
  };

  return (
    <>
      <div className="menu-icon" onClick={toggleDrawer}>
        &#9776;
      </div>
      <div className={`sidebar ${drawerOpen ? 'open' : ''}`}>
        <div className="logo">
          <img src={Logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </div>
        <nav className='navbar-sidebar'>
          <ul >
            <li className='li-navbar'>
              <NavLink to="/agent-dashboard" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <RiDashboardHorizontalLine style={{ fontSize: 20 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Dashboard </p>
              </NavLink>
            </li>
            
            
            
            <li className='li-navbar'>
              <NavLink to="/agent/agent-chat-support" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <PiChatsCircle style={{ fontSize: 18 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Chat Support </p>
              </NavLink>
            </li>
          
          </ul>
          <ul className='ul-list-bottom' style={{marginTop:'24em'}}>
            <li className='li-navbar'>
              <NavLink to="/agent/agent-edit-profile" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <BsPersonVcardFill style={{ fontSize: 16 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Edit Profile </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <NavLink onClick={() => setModalOpen(true)} to='' end className='navlinkLinkatag'>
                <AiOutlineLogout style={{ fontSize: 19 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Logout </p>
              </NavLink>
              <LogoutPopup isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onLogout={HandleLogout}/>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default AgentSidebar;
