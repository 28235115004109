// import Sidebar from 'pages/sideBar/SideBar'
// import React, { useEffect, useState } from 'react'
// import '../app.css'
// import NotificationCom from 'components/notification/NotificationCom'
// import axios from 'axios'
// import Logo from '../../../../assets/images/app.logo.jpeg'
// import { Skeleton } from '@mui/material'
// const Notification = () => {
//   const [data,setData] = useState([])
//   const [loading, setLoading] = useState(true); // Loading state

//   const fetchData = async () => {
//     const token = localStorage.getItem('accessToken');
//     const apiUrl = 'https://backend.washta.com/api/seller/Notifications';

//     try {
//       const response = await axios.get(apiUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data?.data.reverse() || []); // Use optional chaining
//     } catch (err) {
//       console.error('Error fetching notifications:', err.message);
//     } finally {
//       setLoading(false); // Ensure loading is stopped
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchData();
//   }, []);
//   return (
//     <div className='mainDiv-all-sideBar'>
//     <div className='div-sidebar'>
// <Sidebar/>
//     </div>
//     <div className='div-Dashbaord'>
//     <h1 className='h1-all-heading'>Notifications</h1>
//     <div className="dashboard">
//     <div className='styles-div-order'>
// <div className='invoice-border'>
// <div className='chart-colm-div-heading'> 
//           <h2 className='main-heading2' style={{paddingLeft:0}}>Today</h2>
//           </div>
//           <div className='row-details-order'>
//           {loading
//   ? Array.from({ length: 5 }).map((_, index) => (
//       <NotificationCom key={index} isLoading={true} />
//     )) :
// <div style={{width:'100%'}}>
//             {data?.map((item)=>(
//               <div key={item._id} style={{width:'100%',marginBottom:10}}>
//                 <NotificationCom Name={item?.notification?.title} notificationMessages={item?.notification?.body} Date={item.createdAt} ImageNot={item.img || Logo}/>
//                 </div>
//             ))}
//             </div>}
            
//             </div>
//           </div>
//           <div className='invoice-border'>
// <div className='chart-colm-div-heading'> 
//           <h2 className='main-heading2' style={{paddingLeft:0}}>Yesterday</h2>
//           </div>
//           <div className='row-details-order'>
//           {loading
//   ? Array.from({ length: 5 }).map((_, index) => (
//       <NotificationCom key={index} isLoading={true} />
//     )) :
// <div style={{width:'100%'}}>
//             {data?.map((item)=>(
//               <div key={item._id} style={{width:'100%',marginBottom:10}}>
//                 <NotificationCom Name={item?.notification?.title} notificationMessages={item?.notification?.body} Date={item.createdAt} ImageNot={item.img || Logo}/>
//                 </div>
//             ))}
//             </div>}
            
//             </div>
//           </div>
//           </div>
          
//     </div>
//     </div>
//     </div>
//   )
// }

// export default Notification
import Sidebar from 'pages/sideBar/SideBar';
import React, { useEffect, useState } from 'react';
import '../app.css';
import NotificationCom from 'components/notification/NotificationCom';
import axios from 'axios';
import Logo from '../../../../assets/images/app.logo.jpeg';

const Notification = () => {
  const [dataToday, setDataToday] = useState([]);
  const [dataYesterday, setDataYesterday] = useState([]);
  const [loadingToday, setLoadingToday] = useState(true);
  const [loadingYesterday, setLoadingYesterday] = useState(true);

  // Format date to 'YYYY-MM-DD'
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  // Get today's and yesterday's dates
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Fetch notifications for today with date filter
  const fetchTodayNotifications = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = 'https://backend.washta.com/api/seller/Notifications';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: formatDate(today), // Send today's date as a query param
        },
      });

      const notifications = response.data?.data || [];
      const todayNotifications = notifications.filter(item => formatDate(new Date(item.createdAt)) === formatDate(today));
      setDataToday(todayNotifications);
    } catch (err) {
      console.error('Error fetching today\'s notifications:', err.message);
    } finally {
      setLoadingToday(false);
    }
  };

  // Fetch all notifications without date filter for yesterday
  const fetchAllNotifications = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = 'https://backend.washta.com/api/seller/Notifications';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const notifications = response.data?.data || [];
      // const yesterdayNotifications = notifications.filter(item => formatDate(new Date(item.createdAt)) === formatDate(yesterday));
      setDataYesterday(notifications);
    } catch (err) {
      console.error('Error fetching all notifications:', err.message);
    } finally {
      setLoadingYesterday(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTodayNotifications();
    fetchAllNotifications();
  }, []);

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <Sidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Notifications</h1>
        <div className="dashboard">
          <div className='styles-div-order'>
            
            {/* Today's Notifications */}
            <div className='invoice-border'>
              <div className='chart-colm-div-heading'>
                <h2 className='main-heading2' style={{ paddingLeft: 0 }}>Today</h2>
              </div>
              <div className='row-details-order'>
                {loadingToday
                  ? Array.from({ length: 5 }).map((_, index) => (
                      <NotificationCom key={index} isLoading={true} />
                    ))
                  : <div style={{ width: '100%' }}>
                      {dataToday?.map((item) => (
                        <div key={item._id} style={{ width: '100%', marginBottom: 10 }}>
                          <NotificationCom
                            Name={item?.notification?.title}
                            notificationMessages={item?.notification?.body}
                            Date={item.createdAt}
                            ImageNot={item.img || Logo}
                          />
                        </div>
                      ))}
                    </div>
                }
              </div>
            </div>

            {/* Yesterday's Notifications */}
            <div className='invoice-border'>
              <div className='chart-colm-div-heading'>
                <h2 className='main-heading2' style={{ paddingLeft: 0 }}>Yesterday</h2>
              </div>
              <div className='row-details-order'>
                {loadingYesterday
                  ? Array.from({ length: 5 }).map((_, index) => (
                      <NotificationCom key={index} isLoading={true} />
                    ))
                  : <div style={{ width: '100%' }}>
                      {dataYesterday?.map((item) => (
                        <div key={item._id} style={{ width: '100%', marginBottom: 10 }}>
                          <NotificationCom
                            Name={item?.notification?.title}
                            notificationMessages={item?.notification?.body}
                            Date={item.createdAt}
                            ImageNot={item.img || Logo}
                          />
                        </div>
                      ))}
                    </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
