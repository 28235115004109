import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import DELETEICOn from '../../../assets/images/deleteIcon.png';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import LOGO from '../../../assets/images/app.logo.jpeg';
import { CiSearch } from "react-icons/ci";
import './app.css';
import NoDataImage from '../../../assets/images/nodataImg.png'; // Add the path to your image here
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const AllCustomersAppRe = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const fetchData = useCallback(async () => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = 'https://backend.washta.com/api/admin/customer'; // Replace with your API endpoint

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.data.reverse());
    } catch (err) {
      console.log(err.message);
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        // Handle unauthorized error if needed
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const deleteUrl = `https://backend.washta.com/api/admin/customer/${id}`; // Delete API endpoint

    try {
     const response =  await axios.delete(deleteUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.reload()
      setData((prevData) => prevData.filter(item => item.id !== id)); // Remove deleted item from state
    } catch (err) {
      console.error('Error deleting customer:', err.message);
    }
  };

  const filteredData = data.filter(item =>
    item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='div-row-all-customer'>
          <h1 className='h1-all-heading'>All Customers</h1>
          <div className='input-feild-dic-icon all-cutomerssearch'>
            <CiSearch className='icon-serach-box-input' />
            <input
              placeholder='Search by username or email'
              className='input-feild-class-job-history'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%' }}>
            {loading ? (
              Array.from(new Array(10)).map((_, index) => (
                <div key={index} style={{ background: '#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px', marginTop: 20 }}>
                  <Skeleton variant="rectangular" width={48} height={53} style={{ marginRight: '10px', borderRadius: '8px', background: '#747eef9c' }} />
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                </div>
              ))
            ) : filteredData.length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            ) : (
              filteredData.map((item) => (
                <div className='main-invoice-div' style={{ marginTop: 15 }} key={item.id}>
                  <div className='row-invoice' style={{width:'100%'}}>
                    <div className='row-invoice' style={{ justifyContent: 'flex-start',width:'100%' }}>
                     <div className='div-text-image-immer-text' style={{ width: '13%' }}> <img src={item?.avatar||LOGO} style={{ width: '50%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%',objectFit:'cover',height:'7vh' }} /> </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Username</h5>
                        <p className='paragph-heading'>{item.username}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Email</h5>
                        <p className='paragph-heading'>{item.email}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Phone</h5>
                        <p className='paragph-heading'>{item.phone}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Date</h5>
                        <p className='paragph-heading'>{formatDate(item?.updatedAt) + " At "  + formatTimeInTimezone(item?.updatedAt)  }</p>
                      </div>
                      <div onClick={() => handleDelete(item._id)} style={{ cursor: 'pointer' }}>
                        <img style={{ width: 20 }} src={DELETEICOn} alt="Delete Icon" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomersAppRe;
