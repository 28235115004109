import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PendingComp from 'admin/components/application/PendingComp';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { PendingCompSkeleton } from '../SkeletonPending';
import { ToastContainer, toast } from 'react-toastify';
import NoDataImage from '../../../assets/images/nodataImg.png'; // Ensure the correct path to your image

function Rejected() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('rejected', data);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://backend.washta.com/api/admin/businessBystatus?status=rejected'; // API endpoint for rejected applications

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAccept = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = `https://backend.washta.com/api/admin/businessApprove/${id}`; // API endpoint for approving applications

    try {
      const response = await axios.patch(apiUrl, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Approved:', response.data);
      toast.success('User successfully approved');
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (err) {
      console.error('Error approving:', err);
    }
  };
  const gapBtwColmStyle = data?.length === 0 ? {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px',
  } : {};
  return (
    <div className="mainDiv-all-sideBar">
      <div className="div-sidebar">
        <AdminSidebar />
      </div>
      <div className="div-Dashbaord">
        <h1 className="h1-all-heading">Rejected Applications</h1>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%'}}>
            <div className='gap-btw-colm'  style={gapBtwColmStyle}>
              {loading ? (
                Array.from(new Array(7)).map((_, index) => (
                  <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                    <PendingCompSkeleton />
                  </div>
                ))
              ) : data?.length === 0 ? (
                <div style={{}}>
                  <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
              ) : (
                data?.map((item, index) => (
                  <div key={index} style={{ flex: '1 1 calc(25% - 16px)' }}>
                      <PendingComp
                        location={item.business?.location}
                        document={item.business?.uploadDocument}
                        show={false}
                        accept="Accept"
                        reject="Reject"
                        ViewDocument="View Documents"
                        name={item.business?.companyName}
                        date={item.date}
                        time={item.time}
                        email={item.email}
                        valNumber={item.business?.VATNumber}
                        Ceo={item.business?.position}
                        showBtn={false}
                        TextPopupbtnLast="Approve Request"
                        TextpopupbtnHandle={() => handleAccept(item._id)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
   
  );
}

export default Rejected;
