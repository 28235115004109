import React from 'react';
import ClipBord from '../../../assets/images/topcampanies.png'
import TopcampaniesColm from './TopcampaniesColm';
function TopCampanies({data}) {
  
  return (
    <div className="orders">
      <div className='chart-colm-div-heading'> 
          <img src={ClipBord} style={{width:'10%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Top Companies</h2>
          </div>
      <div className="order-list">
        {data.map((data) => { 
          return(
          <div key={data.id} style={{marginTop:20}}>
            <TopcampaniesColm orderdatespan="AED" Cost={data.cost} OrderDate={data.averageMonthlySales}  OrderId={data.totalOrders} MainName={data.shopName} />
            </div>
            )
           
        })}
      </div>
    </div>
  );
}

export default TopCampanies;
