import TopCampanies from 'admin/components/dashboardcom/TopCampanies';
import CompaniesCom from 'admin/components/jobhistory/companies/CompaniesCom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import axios from 'axios';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const Campanies = () => {
   
      const [data, setData] = useState([]);
      const [loading, setLoading] = useState(true);
      const [searchQuery, setSearchQuery] = useState(''); // State for search query
      const navigator = useNavigate();
       const storedData = localStorage.getItem('topcompanies')
      const [topCompaniesSideBardata,settopCompaniesSideBardata] = useState([])
      useEffect(() => {
        window.scrollTo(0, 0);
        if (storedData) {
          try {
              const topCustomerData = JSON.parse(storedData);
              settopCompaniesSideBardata(topCustomerData)
          } catch (error) {
              console.error("Invalid JSON format in localStorage:", error);
          }
      } else {
          console.log("No data found in localStorage.");
      }
        const fetchData = async () => {
          const token = localStorage.getItem('accessTokenAdmin');
          const apiUrl = 'https://backend.washta.com/api/admin/JobHistory';
    
          try {
            const response = await axios.get(apiUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setData(response.data.data.reverse());
          } catch (err) {
            console.log(err.message);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
      // Filter data based on the search query (username or date)
      const filteredData = data.filter(item => {
        const usernameMatch = item.shopId?.shopName.toLowerCase().includes(searchQuery.toLowerCase());
        const dateMatch = formatDate(item?.date).includes(searchQuery);
        return usernameMatch || dateMatch;
      });
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Companies </h1>
    <div className="dashboard">
     
      <div className='styles-div'>

      <div className="chart-container">
        <div className='flex'>
        <div className='input-feild-dic-icon'>
        <CiSearch className='icon-serach-box-input' />
            <input placeholder='Search by service provider' className='input-feild-class-job-history'  value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}/>
        </div>     

        <div className='btn-job-histoiuyr'><ButtonDahbaord handleclick={()=>navigator('/super-admin-dashboard/companies/all-companies',{ state: { item: data }})} textButton="View all" height={35} width={'40%'} background='purple' /></div>
</div>

     <div className="responsive-container">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div key={index} className="responsive-item">
                      <div className="customer-card-skeleton">
                        <div className="avatar-skeleton" style={{display:'flex',gap:'13px',alignItems:'center'}}>
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'30%'} height={60} />
                          <div style={{width:'70%'}}>
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={20} />
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={15} />
                          </div>
                        </div>
                        <div className="info-skeleton">
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={20} />
                          <Skeleton style={{background:'#f1f2fd'}} variant="text" width={'100%'} height={15} />
                          
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  filteredData.map((item) => (
                    <div key={item.id} className="responsive-item">
                     <CompaniesCom
                     item={item}
                     vist={formatDate(item?.date) + " At " + formatTimeInTimezone(item?.date)}
        Loc={item.Loc}
        Service={item.Service}
        ImagePro={item.shopId?.coverImage}
        paymnet={item.paymnet}
        textpopupBtn="Share details"
        status={item.status}
        orderTime={item.vist}
        orderDate={item.OrderDate}
        orderId={item.OrderID}
        // vist={item.vist}
         car={item.carName}
         buyerName={item.shopId?.shopName} show={true} Completed={item.status} name={item.shopId?.shopName} spent={item.shopId?.cost}  />
                    </div>
                  ))
                )}
              </div>
  </div>
      {/* </div> */}
      
      </div>
      <div className='styles-div-text'>
            <TopCampanies data={topCompaniesSideBardata} />
          </div>
    </div>
    </div>
    </div>
  )
}

export default Campanies